import _DatabaseModel from "./_DatabaseModel";

const THREATS_TAB_VULNERABILITIES = 157;

class Threat extends _DatabaseModel {
  static model = "threat";
  static showSize = 8;
  static editDialogComponent = "threat-edit-dialog";
  static tableHeaders = [{ textKey: "form.label.name", value: "name" }];

  static show = {
    buttons: ["edit"],
    submenuActions: ["duplicate", "activity", "destroy"],
    reloadingModels: ["threat_vulnerability", "vulnerability"],
  };

  static filter = {
    parts: {
      fields: ["name", "description", "time_filter"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["export"];

  static basicInfo(object) {
    return [
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
      {
        labelKey: "threat.label.confidentiality",
        value: object.confidentiality,
        type: "boolean",
      },
      {
        labelKey: "threat.label.integrity",
        value: object.integrity,
        type: "boolean",
      },
      {
        labelKey: "threat.label.availability",
        value: object.availability,
        type: "boolean",
      },
    ];
  }
  static tabs = [
    this.detailTab,
    {
      titleKey: "vulnerability.vulnerabilities",
      permission: THREATS_TAB_VULNERABILITIES,
      component: "VulnerabilitiesTab",
      input_data: (context) => context.object.threat_vulnerabilities,
      disabled: () => false,
    },
  ];
}

export default Threat;
