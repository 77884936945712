import _DatabaseModel, { i18n } from "./_DatabaseModel";
import { toSnakeCase } from "../mixins/HelperFunctions";

const SUBSCRIPTIONS_TAB_SUBSCRIBED_ITEMS = 154;

class Subscription extends _DatabaseModel {
  static model = "subscription";
  static editDialogComponent = "subscriptions-edit-dialog";
  static createNewObject = { object: { definition: { checks: [] } } };

  static tableHeaders = [
    {
      textKey: "form.label.name",
      value: "name",
      class: "text-no-wrap",
      sortable: false,
    },
    {
      textKey: "subscription.label.type",
      value: "subscription_type",
      class: "text-no-wrap",
      sortable: false,
    },
    {
      textKey: "notification.tooltip.mail",
      value: "mail",
      align: "center",
      sortable: false,
    },
    {
      textKey: "notification.tooltip.push",
      value: "push",
      align: "center",
      sortable: false,
    },
    {
      textKey: "notification.tooltip.sms",
      value: "sms",
      align: "center",
      sortable: false,
    },
  ];

  static customSort = false;

  static show = {
    buttons: ["edit"],
    submenuActions: ["destroy"],
    reloadingModels: ["entity_subscription"],
  };

  static filter = {
    parts: {
      fields: ["subscription_type"],
      extended_fields: [],
    },
  };

  static subscription_types = [
    "Service",
    "Contract",
    "Rule",
    "Asset",
    "AssetCategory",
    "Person",
    "PersonCategory",
    "Warehouse",
    // "Connector",
    "Other",
  ];

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: i18n.t(
        `${toSnakeCase(object.subscription_type)}.${toSnakeCase(
          object.subscription_type
        )}`
      ),
      actions: ["mail", "push", "sms"],
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "subscription.label.type",
        value: i18n.t(
          `${toSnakeCase(object.subscription_type)}.${toSnakeCase(
            object.subscription_type
          )}`
        ),
        type: "plain",
      },
      {
        labelKey: "notification.label.notification_send_when",
        value: object.definition.checks,
        type: "notification_show",
      },
      {
        labelKey: "notification.label.before_batch_expire",
        value: object.definition.before_batch_expire,
        type: "interval",
        condition: object.subscription_type === "Warehouse",
      },
      {
        labelKey: "notification.label.before_expire",
        value: object.definition.before_expire,
        type: "interval",
        condition: [
          "Rule",
          "Contract",
          "AssetCategory",
          "PersonCategory",
        ].includes(object.subscription_type),
      },
      {
        labelKey: "notification.label.before_notice_period",
        value: object.definition.before_notice_period,
        type: "interval",
        condition: object.subscription_type === "Contract",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "subscription.label.subscribed_items",
      permission: SUBSCRIPTIONS_TAB_SUBSCRIBED_ITEMS,
      component: "SubscribedItemsTab",
      input_data: (context) => context.object,
      disabled: (context) => context.object.subscription_type === "Other",
    },
  ];
}

export default Subscription;
