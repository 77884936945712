import Category from "../classes/Category";
import { useAuthUserStore } from "../stores/authUser";

const ASSET_CATEGORIES_TAB_TEMPLATES = 16;
const ASSET_CATEGORIES_TAB_THREATS = 17;

class AssetCategory extends Category {
  static model = "asset_category";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
  ];

  static createNewObject = {
    ...Category.createNewObject,
    object: { icon: "asset" },
  };

  static basicInfo(object) {
    return [
      {
        labelKey: "category.label.floor_plan",
        value: object.has_floor_plan,
        type: "boolean",
      },
      ...Category.basicInfo(object),
    ];
  }

  static tabs = [
    ...Category.tabs,
    {
      titleKey: "template.templates",
      permission: ASSET_CATEGORIES_TAB_TEMPLATES,
      component: "TemplatesAssignmentsTab",
      input_data: (context) => context.object.template_assignments,
      parameters: { entity_type: "Category" },
      disabled: () => {
        const authUserStore = useAuthUserStore();
        return !authUserStore.permissions.configuration;
      },
    },
    {
      titleKey: "threat.threats",
      permission: ASSET_CATEGORIES_TAB_THREATS,
      component: "ThreatsTab",
      input_data: (context) => context.object.category_threats,
      disabled: () => {
        const authUserStore = useAuthUserStore();
        return !authUserStore.permissions.configuration;
      },
    },
  ];
}

export default AssetCategory;
