import _DatabaseModel from "./_DatabaseModel";

const ASSETS_TAB_CALENDAR = 22;
const ASSETS_TAB_DOCUMENTS = 23;
const ASSETS_TAB_EXTERNAL_GUIDS = 24;
const ASSETS_TAB_ISSUES = 25;
const ASSETS_TAB_LOCATIONS = 26;
const ASSETS_TAB_NETWORK_GRAPH = 27;
const ASSETS_TAB_PERSONS = 28;
const ASSETS_TAB_SERVICES = 29;
const ASSETS_TAB_TIMELINE = 30;
const ASSETS_TAB_WORK_REPORTS = 31;
const ASSETS_TAB_RULES = 167;

class Asset extends _DatabaseModel {
  static model = "asset";
  static showSize = 7;
  static autofillParent = true;
  static editDialogComponent = "persons-and-assets-edit-dialog";

  static bottomNav = {
    mobile: ["card", "tree", "map"],
    desktop: ["list", "tree", "map"],
  };

  static createNewObject = {
    object: { category_id: null },
    params: { direct: true },
  };

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
    {
      textKey: "category.category",
      class: "text-no-wrap",
      value: "category.name",
    },
    { textKey: "form.label.archived_at", value: "discarded_at" },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: [
      "duplicate",
      "createIssue",
      "manageSubscription",
      "permittedPersons",
      "activity",
      "archive",
    ],
    reloadingModels: [
      "person_asset",
      "location",
      "external_guid",
      "indoor_location",
      "asset_link",
    ],
  };

  static filter = {
    parts: {
      fields: [
        "name",
        "id",
        "parent_id",
        "category_id",
        "tag_definitions",
        "detail",
        "persistence",
      ],
      extended_fields: [
        "description",
        "person_relation_id",
        "person_id",
        "service_id",
        "connector_id",
        "time_filter",
      ],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = [
    "export",
    "report",
    "link_to_document",
    "change_parent",
    "add_tag_to_entity",
    "remove_tag_from_entity",
    "link_assets_to_person",
    "remove_assets_from_person",
    "archive_multiple",
    "destroy_multiple",
  ];

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: object.category.name,
      src: object,
      tags: true,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "category.category",
        value: object.category,
        type: "link",
        to: "asset_categories",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.archived_at",
        value: object.discarded_at,
        type: "date_time",
        condition: object.discarded_at,
      },
    ];
  }

  static tabs = [
    {
      titleKey: "template.detail",
      component: "TemplatesDetailTab",
      input_data(context) {
        return {
          object: context.object,
          templatable_id: context.object.category_id,
          properties: context.object.properties,
          template_assignments: context.object.template_assignments,
        };
      },
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "issue.label.timeline",
      permission: ASSETS_TAB_TIMELINE,
      component: "TimelineTab",
      disabled: () => false,
    },
    {
      titleKey: "risk.label.network_graph",
      permission: ASSETS_TAB_NETWORK_GRAPH,
      component: "AssetNetworkGraphTab",
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "document.documents",
      permission: ASSETS_TAB_DOCUMENTS,
      component: "DocumentsTab",
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "person.persons",
      permission: ASSETS_TAB_PERSONS,
      component: "PersonAssetsTab",
      parameters: { target_entity: "person" },
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "service.services",
      permission: ASSETS_TAB_SERVICES,
      component: "ServicesOnAssetTab",
      input_data: (context) => context.object,
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "rule.rules",
      permission: ASSETS_TAB_RULES,
      component: "RulesOnAssetTab",
      input_data: (context) => context.object,
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "issue.issues",
      permission: ASSETS_TAB_ISSUES,
      component: "IssuesTab",
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "calendar.calendar",
      permission: ASSETS_TAB_CALENDAR,
      component: "CalendarTab",
      disabled: () => false,
    },
    {
      titleKey: "location.location",
      permission: ASSETS_TAB_LOCATIONS,
      component: "LocationsTab",
      input_data: (context) => context.object,
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "external_guid.external_guids",
      permission: ASSETS_TAB_EXTERNAL_GUIDS,
      component: "ExternalGuidsTab",
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "work_report.work_reports",
      permission: ASSETS_TAB_WORK_REPORTS,
      component: "WorkReportsOnAssetsPersonsTab",
      disabled: () => false,
    },
  ];
}

export default Asset;
