import _DatabaseModel, { i18n } from "./_DatabaseModel";

const ASSET_GROUPS_TAB_ASSETS = 19;
const ASSET_GROUPS_TAB_CONTROLS = 20;

class AssetGroup extends _DatabaseModel {
  static model = "asset_group";
  static showSize = 8;
  static editDialogComponent = "asset-groups-edit-dialog";
  static tableHeaders = [{ textKey: "form.label.name", value: "name" }];

  static show = {
    buttons: ["edit"],
    submenuActions: ["activity", "destroy"],
    reloadingModels: [],
  };

  static filter = {
    parts: {
      fields: ["name", "time_filter"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static cardFields(object) {
    return {
      title: object.name,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "category.category",
        value: object.category,
        type: "link",
        to: "asset_categories",
      },
      {
        labelKey: "form.label.confidentiality",
        value: i18n.t(
          `primary_asset.confidentiality_types.${object.cia?.confidentiality}`
        ),
        type: "plain",
        condition: !!object.cia,
      },
      {
        labelKey: "form.label.integrity",
        value: i18n.t(`primary_asset.integrity_types.${object.cia?.integrity}`),
        type: "plain",
        condition: !!object.cia,
      },
      {
        labelKey: "form.label.availability",
        value: i18n.t(
          `primary_asset.availability_types.${object.cia?.availability}`
        ),
        type: "plain",
        condition: !!object.cia,
      },
      {
        labelKey: "primary_asset.primary_assets",
        value: object.primary_assets,
        type: "link_array",
        to: "primary_assets",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "asset.assets",
      permission: ASSET_GROUPS_TAB_ASSETS,
      component: "AssetGroupAssetsTab",
      input_data: (context) => context.object,
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "control.controls",
      permission: ASSET_GROUPS_TAB_CONTROLS,
      component: "AssetGroupAppliedControlsTab",
      input_data: (context) => context.object.applied_controls,
      disabled: (context) => context.dataShow_disableUpdate,
    },
  ];
}

export default AssetGroup;
