const genericAlert = {
  verb: "was",
  create: "created",
  update: "updated",
  destroy: "deleted",
  archive: "archived",
  unarchive: "unarchived",
  new: "New",
  expired: "expired",
};

const en = {
  action: {
    edit_state: "Edit state",
    update: "Update",
    load: "Load",
    export: "Export",
    back: "Back",
    next: "Next",
    select: "Select",
    cancel: "Cancel",
    create: "Create",
    save: "Save",
    save_new: "Save new",
    delete: "Delete",
    destroy: "Delete",
    remove_personal_data: "Remove personal data",
    remove: "Remove",
    close: "Close",
    duplicate: "Duplicate",
    archive: "Archive",
    unarchive: "Unarchive",
    manage_subscription: "Manage subscriptions",
    edit: "Edit",
    all: "All",
    apply: "Apply",
    link: "Link",
    unlink: "Unlink",
    impersonate: "Impersonate",
    add_text: "Add {type}",
    add_material: "Add material",
    add_work: "Add work",
    add_transport: "Add transport",
    add_note: "Add note",
    add_transition: "Add transition",
    create_threat: "Create threat",
    create_vulnerability: "Create vulnerability",
    create_control: "Create control",
    add_field: "Add field",
    add_section: "Add section",
    add_provision: "Add provision",
    create_price_item: "Create price item",
  },
  app_parts: {
    about: "About",
    account: "Account",
    activities: "Activites",
    main_administration: "Administration",
    asset_categories: "Asset categories",
    asset_groups: "Supporting assets",
    assets: "Assets",
    audits: "Audits",
    automated_actions: "Automated actions",
    background_jobs: "Background jobs",
    connectors: "Connectors",
    contracts: "Contracts",
    controls_management: "Controls management",
    dashboard_screens: "Dashboards",
    data_sets: "Filters",
    devices: "Devices",
    diagnostics: "Diagnostics",
    document_settings: "Document settings",
    documents: "Documents",
    standby_plan: "Standby plan",
    external_emails: "External emails",
    fields: "Fields",
    health_check: "Health check",
    holidays: "Holidays",
    consequences: "Consequences",
    main_implementation: "Implementation",
    import_assets: "Assets imports",
    import_contracts: "Contracts import",
    import_documents: "Documents import",
    import_fields: "Fields import",
    import_holidays: "Holidays import",
    import_issues: "Issues import",
    import_controls: "Controls import",
    import_persons: "Persons import",
    import_price_items: "Items import",
    import_price_lists: "Price items import",
    import_rules: "Rules import",
    import_services: "Services import",
    import_standards: "Standards import",
    import_tag_definitions: "Tags import",
    import_templates: "Templates import",
    import_threats: "Threats import",
    import_translations: "Translations import",
    import_users: "Users import",
    import_issue_priorities: "Issue priorities import",
    import_issue_states: "Issue states import",
    import_person_relations: "Person relations import",
    import_vulnerabilities: "Vulnerabilities import",
    import_warehouses: "Warehouse items import",
    installation: "Installation",
    issue_priorities: "Issue priorities",
    issue_states: "Issue states",
    issues: "Issues",
    kpis: "KPI",
    license: "License",
    mail_server: "Email server",
    main_assets: "Assets",
    main_audits: "Audits",
    main_connectors: "Connectors",
    main_dashboard_screens: "Dashboards",
    main_documents: "Documents",
    main_issues: "Issues",
    main_persons: "Persons",
    main_risk_management: "Risk management",
    main_services: "Services",
    main_warehouses: "Warehouses",
    maintenance_plan: "Maintenance plan",
    mappers: "Mappers",
    controls: "Controls",
    notice: "Notice",
    notifications: "Notifications",
    permissions: "Permissions",
    person_relations: "Person relations",
    person_categories: "Person categories",
    persons: "Persons",
    linkages_setting: "Linkages setting",
    planning_calendar: "Planning calendar",
    preferences: "Preferences",
    price_items: "Items",
    price_lists: "Price lists",
    primary_assets: "Primary assets",
    likelihoods: "Likelihoods",
    profile: "Profile",
    public_form: "Public issue form",
    reminders: "Reminders",
    reports: "Reports",
    risk_management: "Risk management",
    risk_levels: "Risk levels",
    risks: "Risk analysis",
    roles: "Roles",
    rules: "Rules",
    services: "Services",
    main_settings: "Settings",
    snippets: "Snippets",
    soas: "Statements of applicability",
    standard_soas: "Statements of applicability",
    standards: "Standards",
    statistics: "Statistics",
    subscriptions: "Subscriptions",
    tag_definitions: "Tags",
    threats: "Threats",
    translations: "Translations",
    user_settings: "Default user settings",
    users: "Users",
    vulnerabilities: "Vulnerabilities",
    warehouses: "Warehouses",
  },
  form: {
    hint: {
      global_search: "Search for item by name...",
    },
    placeholder: {
      date: "YYYY-MM-DD",
      time: "HH:MM",
    },
    label: {
      acceptable: "Acceptable",
      activity_type: "Activity type",
      activity: {
        entity_id: "Object ID",
        entity_type: "Object type",
        person_id: "Author person",
        connector_id: "Author connector",
      },
      aggregate: "Aggregate",
      apply: "Apply",
      apply_from: "Apply from",
      apply_to: "Apply_to",
      archive_all: "Archive an object with all children",
      archived_at: "Archived",
      asset_category_id: "Asset category",
      asset_resource_id: "Asset (Resource)",
      asset_id: "Asset",
      asset_with_children: "With children items",
      assignee: "Assignee",
      attempts: "Attempts",
      audit_id: "Audit",
      author: "Author",
      author_system: "Author system",
      auto_center: "Auto center",
      automated_action_id: "Author automated action",
      automated_action_type: "Automated action type",
      availability: "Availability",
      blocked: "Blocked",
      budget: "Budget",
      calculation: "Calculation",
      cancel: "Cancel",
      category_id: "Category",
      change_parent: "Change the parent of all children",
      character: "Char.",
      closed_at: "Closed at",
      code_1: "Code 1",
      code_2: "Code 2",
      column_filter: "Column filter",
      confidentiality: "Confidentiality",
      connector_id: "Connector",
      contract_id: "Contract",
      contract_type: "Contract type",
      count: "Count",
      created_at: "Created at",
      created_by: "Created by",
      created_from: "Created from",
      created_to: "Created to",
      creator_id: "Author person",
      currency: "Currency",
      current_hint: "0 means current",
      customer_id: "Customer",
      dashboard_screen_id: "Dashboard",
      data_set_id: "Filter",
      data_type: "Data type",
      date_from: "Date from",
      date_to: "Date to",
      description: "Description",
      detail: "Field in detail",
      detail_on_asset: "Field in asset detail",
      display: "Display",
      effective_at: "Effective at",
      email: "Email",
      en: "Translation EN",
      entity_id: "Relation ID",
      entity_type: "Object type",
      error: "Error loading filter",
      evidence: "Evidence",
      expense: "Expense",
      external_email_id: "Author external email",
      failed_at: "Failed",
      field: "Group by",
      file: "File",
      file_size_range: "Size",
      files: "Files",
      findings: "Findings",
      fixed: "Fixed",
      from: "From",
      gdpr_privacy_policy: "Privacy Policy",
      gtin: "GTIN",
      id: "ID",
      identifier: "Identifier",
      information_unavailable: "Information unavailable",
      integrity: "Integrity",
      issue: {
        person_id: "Issue Assignee",
      },
      issue_priority_id: "Priority",
      issue_state_id: "State",
      kpi_id: "KPI",
      key: "Key",
      last: "Last",
      mapper_id: "Mapper",
      maximum: "Maximum",
      control_id: "Control",
      minimum: "Minimum",
      name: "Name",
      new_parent: "Move under parent",
      no_value_option_asset: "Show assets without {type}",
      no_value_option_asset_risk: "Show risks without {type}",
      no_value_option_document: "Show documents without {type}",
      no_value_option_issue: "Show issues without {type}",
      no_value_option_person: "Show persons without {type}",
      no_value_option_requirement: "Show requirements without {type}",
      not_planned: "Not planned",
      offset: "Offset",
      on: "Turn on",
      operations: "Operations",
      operations_header: "Operations over selected items",
      order: "Order",
      organization_id: "Organization",
      otp_required_for_login: "Two factor authentication",
      overlap: "Overlap",
      paragraph: "§",
      parent_id: "Belongs to",
      period: "Period",
      period_filter_method: "Period filtering method",
      persistence: "Existence",
      person_relation_id: "Relation",
      person_category_id: "Person category",
      person_id: "Person",
      picture: "Picture",
      point: "Point",
      price_list_id: "Price list",
      price_type: "Item type",
      primary_asset_id: "Primary asset",
      public: "Public",
      public_token_valid: "Only public",
      range: "Range",
      receipt: "Receipt",
      relation_type_id: "Relation ID",
      report_id: "Report",
      report_type: "Report type",
      resolve_at: "Resolve at",
      risk_reduction: "Risk reduction",
      risk_level_id: "Risk level",
      role_id: "Role",
      row: "Row",
      rule_id: "Rule",
      run_at: "Run",
      run_type: "Run type",
      service_id: "Service",
      service_organization_id: "Service organization",
      service_type: "Service type",
      show_legend: "Show legend",
      sk: "Translation SK",
      solution: "Solution",
      solve_status: "Solving status",
      sort: "Sort by",
      sort_desc: "Sort descending",
      standard_id: "Law and Standard",
      standard_soa_id: "Statement of Applicability",
      start_of_working_hours: "Start of working hours",
      state: "State",
      subscription_type: "Subscription type",
      subsection: "Sec.",
      sum: "Sum",
      table_headers: "Table columns",
      tag_definitions: "Tags",
      tag_definition_id: "Tag",
      target_entity: "Target data type",
      threat_id: "Threat",
      tier: "User type",
      till_now: "till now",
      time_filter: "Date field",
      time_from: "Time from",
      time_to: "Time to",
      to: "To",
      transfer: "Transfer",
      type: "Type",
      unit: "Unit",
      updated_at: "Last update at",
      valid_at: "Valid at",
      valuation: "Valuation",
      value: "Value",
      value_in_detail: "Value in detail",
      visualization: "Visualization",
      vulnerability_id: "Vulnerability",
      warehouse_id: "Warehouse",
      with_user: "With user",
      source_warehouse_id: "Source warehouse",
      target_warehouse_id: "Target warehouse",
      price_item_id: "Item",
      whole: "Whole",
      with_children: "With children items",
      working_color: "Icon color for assets without issue",
      working_hide: "Do not display assets without issues",
      xtitle: "X-axis description",
      ytitle: "Y-axis description",
      zoom: "Zoom",
    },
    error: {
      required: "This field is required",
      valid: "This field is invalid",
      length_min_max: "Must contain min {min} and max {max} characters",
      length_min: "Must contain min {min} characters",
      length_max: "Must contain max {max} characters",
      json: "Must contain valid JSON",
      value_min_max: "Value must be min {min} and max {max}",
      value_min: "Value must be min {min}",
      value_max: "Value must be max {max}",
      value_higher: "Value must be higher than {value}",
      value_lower: "Value must be lower than {value}",
      value_higher_equal_zero: "Value must be equal or higher than 0",
      value_custom_range:
        "Value must be equal or higher than {min} and equal or lower than {max}",
      value_higher_equal_0_lower_equal_custom_value:
        "Value must be equal or higher than 0 and equal or lower than {value}",
      not_zero: "Value can't be 0",
      email: "Must be valid email address",
      multi_email: "Must contain only valid email addresses",
      big_file: "File size exceeds limit",
      not_in_past: "Date can't be in past",
      must_contain_number: "Must contain at least one digit",
      must_contain_lower: "Must contain at least one lower case letter",
      must_contain_upper: "Must contain at least one upper case letter",
      items_min_max: "Number of items must be min {min} and max {max}",
      later_date: "Date must be later than '{prev}'",
      match_password: "Must match password",
    },
  },
  enum: {
    boolean: {
      no: "No",
      yes: "Yes",
    },
    week_first_day: {
      monday: "Monday",
      sunday: "Sunday",
    },
  },
  other: {
    actions: "Actions",
    info: "Info",
    summary: "Summary",
    other: "Other",

    tags: {
      subscribe: "Notifications turned on",
      children_count: "Number of child items",
    },
    basic_info: "Basic information",
  },
  first_login: {
    cookies:
      "I am aware that the application uses cookies and I agree to that ",
    personal_info_GDPR: "I accept processing my personal information ",
    license: "I accept EULA",
    accept: "Accept",
    decline: "Decline",
    welcome: "Welcome in AUSEMIO",
    link_text: "More information",
    headers: {
      cookies: "About cookies usage on this page",
      gdpr: "Data processing",
    },
  },
  tooltips: {
    navbar_icons: {
      log_out: "Log out",
      locales_switch: "Select language",
      notifications: "Notifications",
      documentation: "Documentation",
      search: "Use # + Id number for search by Id",
      search_shortcut: "Search ( S )",
      stop_impersonating: "Stop impersonating user",
      list: "List view",
      card: "Cards view",
      tree: "Tree view",
      map: "Map view",
      cal: "Calendar view",
    },
    show_buttons: {
      add: "Create new object",
      add_primary_asset_group: "Add supporting assets",
      add_new_transition: "Add new state transition",
      add_shortcut: "Create new object ( C )",
      show_previous_note: "Show previous note",
      hide_previous_note: "Hide previous note",
      apply_plan_shortcut: "Apply plan ( A )",
      apply_controls: "Apply controls",
      new_note: "Create new note",
      reply_to_note: "Reply to {type} note",
      forward_note: "Forward",
      copy_link: "Copy link to note",
      edit: "Edit object",
      change_state: "Change issue state",
      add_location: "Add location",
      edit_location: "Edit location",
      new_document: "Create and link new document",
      import: "Import from file",
      new_material_report: "New material report",
      new_work_report: "New work report",
      new_transport_report: "New transport report",
      entity_link: "Link object",
      link_document: "Link existing document",
      show_in_documents: "Open in Documents",
      show_in_issues: "Open in Issues",
      run_applying: "Start applying the control",
      passwd_change: "Change password",
      other: "Other actions",
      new_option: "New option",
      add_issue: "Create issue",
      add_floor_plan: "Add floor plan",
      edit_floor_plan: "Edit floor plan",
      add_new_assignee: "Add new assignee",
      operations: "Operations ( O )",
      filter: "Filter ( F )",
      filter_inside: "Filter ( Shift + Enter )",
      filter_actions: "Filter actions",
      filter_config: "Filter configuration",
      clear_filter_shortcut: "Clear filter ( Shift + Backspace )",
      apply: "Apply",
      setup_permissions: "Setup permissions",
      navigate: "Navigate",
      download: "Download",
      use_translation: "Use translation",
      find_by_qr: "Find asset by BAR/QR code",
      use_optional_translation: "Use text without translation",
      add_DataSet_dashlet: "Add filter dashlet",
      add_Kpi_dashlet: "Add KPI dashlet",
      add_Calendar_dashlet: "Add calendar dashlet",
      add_threat: "Create and add new threat",
      add_vulnerability: "Create and add new vulnerability",
      add_control: "Create and add new control",
      display_hidden: "Display hidden issues",
      add_section: "Add new section",
      add_provision: "Add new provision",
      add_price_item: "Add new price item",
      create_template_assignment: "Create template",
      apply_planned_control: "Apply planned control",
      disabled_buttons: {
        add: "You do not have permission to create new object",
        add_new_transition:
          "You do not have permission to add new state transition",
        new_note: "You do not have permission to create new note",
        edit: "You do not have permission to edit object",
        new_location: "You do not have permission to create location",
        no_filter: "You have no active filter",
        generic: "You do not have permission for this action",
        fields_echo: "You have not entered all fields",
        no_issue: "No new issue needed",
        no_warehouse_items: "This item is out of stock",
        warehouse_delete: "Balance and ordered must be 0",
        no_batches: "Item has no batches",
        no_layer: "You have no layer",
        reminder_exp: "Reminder expired",
      },
    },
    tabs_icons: {
      search: "Search",
      save: "Save",
      delete: "Delete",
      edit: "Edit",
      priority_move_up: "Increase priority",
      priority_move_down: "Decrease priority",
      move_up: "Move up",
      move_down: "Move down",
      checklist_preview: "Checklist preview",
      empty: "No tooltip defined !!!",
      add: "Add",
      preview: "Preview",
      detail: "Show detail",
      show_in_cal: "Show all resources in calendar",
      history_of_asset_issues: "View the history of issues on this asset",
    },
    show_assets_calendar_tooltip: {
      table_view: "Table",
      graphic_view: "Plan",
    },
  },
  generic: {
    application: "Application",
    archive: "Archive",
    back_to_login: "Back to login",
    camera: "Camera",
    code: "Code",
    conf_new_passwd: "Confirm new password",
    drop_files_message: "Drop files here to upload",
    error_value: "Error value",
    false: "No",
    filter_active: "Filter is active",
    forgot: "Forgot your password?",
    item: "Item",
    log_in: "Log in",
    log_in_header: "Log in",
    message: "Message",
    new_passwd: "New password",
    object: "Object",
    others: "others",
    password: "Password",
    copyright: "Copyright © 2023",
    reader: "Reader",
    run: "Run",
    scan: "Scan",
    scan_qr: "Scan QR/BAR Code",
    send_reset: "Restore",
    set_passwd: "Set new password",
    show_less: "Show less",
    show_more: "Show more",
    system: "System",
    total_count: "Total count",
    true: "Yes",
    two_factor_auth_code: "Verification code",
    unknown: "Unknown",
    unset: "Unset",
  },
  date_parts: {
    second: "second",
    minute: "minute",
    hour: "hour",
    day: "day",
    week: "week",
    month: "month",
    year: "year",
  },
  // TC translations
  day: "1 day | {n} days",
  week: "1 week | {n} weeks",
  month: "1 month | {n} months",
  year: "1 year | {n} years",
  messages: {
    publicIssue: {
      cameraAllow: "Allow camera permission to scan QR",
      noCamera: "ERROR: No camera on this device",
      httpsNeeded: "HTTPS is needed to scan QR code",
      cameraInUse: "ERROR: Is the camera already in use?",
      cameraFront: "Device does not have front or rear camera",
      streamAPI: "ERROR: Stream library is not supported in this browser",
    },
    login: {
      success: "Successfully logged in",
      error: "Failed to logged in. Wrong email or password.",
      error_two_factor: "Failed to logged in. Invalid authentication code.",
    },
    logout: "Successfully logged out",
    pageLoadAuthenticationError:
      "Authentication failed. Please log in to continue.",
    appPartNoAccess: "You don't have permission on this application part",
    passwordReset: {
      error:
        "Error setting new password: {errors}. To generate a new password reset link, click on 'RESTORE'. If the error persists, please contact the administrator.",
      success: "Password changed",
      instructionsSend:
        "We have sent you a password reset link to your email account. Follow the steps to reset your password. In case of non-delivery, please check the spam or the correctness of the entered email through which you are registered with us.",
      instructionsSendError:
        "Error sending reset password instructions by email",
    },
    globalSearchError: "Can't load results",
    treeLoadError: "Error loading tree",
    will_permanently: "will be permanently",
    question: "Do you want to continue?",
    cant: "Can't",
    noPermissionError: "You don't have permissions.",
    select: "Select",
    noDataListPermissions: "You don't have permissions to any item",
    empty_import_file: "Your file does not contain data for import",
    mapTooMuchData:
      "You have selected too many objects (more than 1000), the simultaneous display of which would make the visualization on the map significantly slower. Use the filter to select a smaller set.",
    moreThenAnother: "{firstName} must be greater than {secondName}.",
    check: "You probably didn't enter all the fields correctly",
    scanErr: "Invalid GTIN",
    scanErrorBatch: "A batch is required for this scanned item",
    notInList: "Item {name} {batch} not on the list",
    no_icon: "Please select icon",
    notInChecklist: "This asset is not part of this checklist",
    close_transaction:
      "Closing a transaction, this step will definitely close your transaction, it will no longer be possible to change it.",
    license: {
      user_exceeded:
        "Thank you for using the AUSEMIO platform. To add more users, please primarily contact your supplier or contact us at {web}.",
      connector_exceeded:
        "Thank you for using the AUSEMIO platform. To add more connectors, please primarily contact your supplier or contact us at {web}.",
    },
    original_parent_archived:
      "The original parent is archived, please select a new parent",
    remove_personal_data:
      "Removes the personal data in the person tab and all data with the personal data flag in the templates.",
    personal_data_removed: {
      success: "Personal data has been removed.",
      error: "It is not possible to remove personal data.",
    },
    setup_persons: {
      success: "Persons have been set.",
      error: "It is not possible to set persons.",
    },
    impersonate: {
      success: "You are now impersonating user {person}",
    },
  },
  dataTableMessages: {
    filter: {
      name: "Filter items by name",
    },
    emptyData: {
      headerPt1: "No data visible for ",
      headerPt2: ", here are tips why",
      filterReason: "No data available for chosen filter conditions",
      emptyDatabaseReason: "No created data in database",
      permissionReason: "You do not have permissions to see data",
      genericEmptyDataMessage:
        "There are no objects created or you do not have permission to see them",
      loading: "Loading data, please wait",
      scanner: "Please start scanning items",
      warehouse: "Please select warehouse",
    },
    bottomNavbar: {
      list: "List",
      tree: "Tree",
      map: "Map",
      card: "Cards",
    },
  },
  sorts: {
    asc: "Ascending",
    desc: "Descending",
    created_at: {
      asc: "Oldest first",
      desc: "Newest first",
    },
    updated_at: {
      asc: "Long ago",
      desc: "Recently",
    },
    discarded_at: {
      asc: "Long ago",
      desc: "Recently",
    },
    name: {
      asc: "A - Z",
      desc: "Z - A",
    },
    category: {
      name: {
        asc: "A - Z",
        desc: "Z - A",
      },
    },
    issue_priority_id: {
      asc: "Lowest first",
      desc: "Highest first",
    },
  },
  person: {
    person: "Person",
    persons: "Persons",
    acc: "person",
    tree: "Persons tree",
    alert: genericAlert,
    label: {
      avatar: "Picture",
      submenu: {
        new_user: "Create user",
      },
      associated_user: "Create person with associated user",
    },
    hint: {
      roles: "Select roles that belongs to person",
    },
  },
  asset: {
    asset: "Asset",
    assets: "Assets",
    acc: "asset",
    tree: "Assets tree",
    card: "Asset cards",
    alert: genericAlert,
    label: {
      avatar: "Picture",
      guid: "GUID",
      properties: "Asset properties",
      submenu: {
        new_issue: "Create issue",
      },
      dependent: "Dependent on",
      depends: "Depends on",
      view_all: "Display",
    },
    select_type: "Asset selection type",
    asset_filters: "Asset filters",
    search_assets: "Search assets",
  },
  diagnostics: {
    diagnostics: "Diagnostics",
    nav: {
      overview: "Overview",
      alarms: "Alarms",
      logs: "Logs",
    },
    device: {
      status: {
        true: "On",
        false: "Off",
        failure: "Failure",
      },
      label: {
        leakage: "Leakage",
        detail: "Detail",
        detail_tooltip: "View detailed information about the device",
        reverse: "Reverse operation",
        temperature: "°C",
        invalid: "Communication error",
      },
    },
    units: {
      voltage: "Voltage",
      voltage_short: "V",
      current: "Current",
      graph_current_mean: "Mean current",
      graph_current_max: "Max current",
      graph_current_min: "Min current",
      current_short: "A",
      temperature: "Temperature",
      graph_temperature_mean: "Mean temperature",
      graph_temperature_max: "Max temperature",
      graph_temperature_min: "Min temperature",
      temperature_short: "°C",
      leakage: "Leakage",
      graph_leakage_mean: "Mean leakage",
      graph_leakage_max: "Max leakage",
      graph_leakage_min: "Min leakage",
      engine_hours: "Engine hours",
      engine_hours_short: "",
    },
    alarms: {
      headers: {
        device_name: "Device name",
        created_at: "Created at",
        error: "Error",
      },
      labels: {
        devices: "Devices",
        fields: "Alarms",
        history: "Alarm history",
        active: "Active alarms",
      },
      messages: {
        alarm_temperature: "Exceeding the temperature",
        alarm: "General alarm",
        alarm_dry_run: "Dry run",
        trouble: "Trouble",
        alarm_leakage: "Leakage",
        no_active_alarms: "No active alarms",
      },
    },
    logs: {
      headers: {
        device_name: "Device name",
        kind: "Kind",
        created_at: "Created at",
        end_time: "End time",
      },
      types: {
        value: "Measurement",
        alarm: "Alarm",
      },
      fields: {
        current: "Current",
        current_short: "A",
        temperature: "Temperature",
        leakage: "Leakage",
        leakage_short: "",
        temperature_short: "°C",
        engine_hours: "Engine hours",
        engine_hours_short: "",
        num_starts: "Number of starts",
        num_starts_short: "",
        num_rev_starts: "Number of reverse starts",
        num_rev_starts_short: "",
        alarm_temperature: "Exceeding the temperature",
        alarm: "General alarm",
        alarm_dry_run: "Dry run",
        trouble: "Trouble",
        alarm_leakage: "Leakage",
        is_reversed: "Reverse operation",
        is_running: "On",
      },
    },
    graph: {
      buttons: {
        day: "Daily",
        hour: "Hourly",
      },
      labels: {
        time: "Time",
        nominal: "Nominal value",
        max: "Max value",
      },
      units: {
        current: "Current [A]",
        temperature: "Temperature",
        leakage: "Leakage",
      },
    },
  },
  temperature: {
    acc: "temperature",
  },
  current: {
    acc: "current",
  },
  leakage: {
    acc: "leakage",
  },
  alarm: {
    acc: "alarm",
  },
  log: {
    acc: "log",
  },
  document: {
    document: "Document",
    documents: "Documents",
    acc: "document",
    label: {
      entity_document: "Linked objects",
      dialog_entity_type: "Object type",
      select_objects: "Select object",
      less_than_1_mb: "Less than 1 MB",
      size: "Size",
      gallery: "Gallery of documents",
      table_view: "Table",
      gallery_view: "Gallery",
      noPreview: "A preview of this file type is not available",
      preview: "Preview",
      public_token_valid: "Public",
      attachments: "Attachments",
      without_relation: "Without relation",
      submenu: {
        compress: "Compress Image",
        new_folder: "Create new folder",
        add_doc: "Add document",
      },
    },
    public_form: {
      sorry: "Sorry, can't display document.",
    },
    alert: genericAlert,
  },
  folder: {
    folder: "Folder",
    acc: "folder",
    alert: genericAlert,
    files_location: "Location of uploaded files",
  },
  contract: {
    contract: "Contract",
    contracts: "Contracts",
    acc: "contract",
    alert: genericAlert,
    contract_types: {
      fixed_prolong: "Fixed length with automatic prolongation",
      fixed_end: "Fixed length",
      indefinite: "Indefinite period",
    },
    label: {
      customer: "Customer",
      service_organization: "Service organization",
      contract_type: "Contract type",
      valid_from: "Valid from",
      effective_from: "Effective from",
      effective_to: "Effective to",
      effective_at: "Effective at",
      prolongation_interval: "Prolongation interval",
      notice_period: "Notice period",
      submenu: {},
    },
    hint: {
      customer: "Person that receives service",
      service_organization: "Person that provides service",
      valid_from: "Start of validity",
      effective_from: "Start of effectiveness",
      effective_to: "End of effectiveness and validity",
      notice_period: "Interval of notice period",
      prolongation_interval: "Interval of prolongation",
    },
  },
  service: {
    service: "Service",
    services: "Services",
    acc: "service",
    alert: genericAlert,
    label: {
      automated_planning: "Automated revision plan updates",
      planned_revisions: "Number of planned revisions",
      resolve_plan: "Planned resolving",
      resolve_true: "True resolving",
      assets_revisions: "Asset revisions",
      service_type: "Service type",
      workflow: "Workflow",
      copy_workflow: "Copy workflow",
      copy_templates: "Copy templates",
      copy_role_assignments: "Copy role assignments",
      copy_sla: "Copy SLA",
      copy_permissions: "Copy permissions",
      setup_all: "Select a checklist for all categories",
      submenu: {
        new_issue: "Create issue",
        workflow_diagram: "Generate workflow diagram",
      },
      without: "Without service",
      allowed_tags: "Allowed tags",
    },
    messages: {
      hint: "The diagram will be generated in the background and the file will be saved to service documents. You will be notified about completion by a notification in the application.",
    },
    deprecated:
      "Periodic services will be removed from next version. Set up issue generation through Rules.",
    service_types: {
      periodic: "Periodic",
      reactive: "Reactive",
    },
    add_assets_dialog: "Setup assets for service",
  },
  service_person: {
    service_person: "Service person",
    service_persons: "Service persons",
    acc: "service person",
    alert: genericAlert,
    label: {
      standby_plan: "Standby plan",
      recommended_persons: "Recommended persons",
      auto_assign_restrictions: "Auto assign restrictions",
    },
  },
  standby_time: {
    standby_time: "Standby time",
    standby_times: "Standby times",
    acc: "standby time",
    alert: genericAlert,
  },
  rule: {
    rule: "Rule",
    rules: "Rules",
    acc: "rule",
    alert: genericAlert,
    label: {
      valid_from: "Valid from",
      valid_to: "Valid to",
      repeat: "Repeat",
      in_advance: "Create issue in advance",
      required_by_law: "Required by law",
      valid_at: "Valid at",
      filter: "Filter definition",
    },
    tooltip: {
      no_issue: "No issue assigned",
      missing_resolve_at: "Issue assigned without resolve at",
      create_issue: "Create initialization issue for selected assets",
      select_asset: "Select at least 1 asset",
    },
    hint: {
      repeat: "Interval between service check",
    },
  },
  calendar: {
    calendar: "Calendar",
    planning_calendar: "Planning calendar",
    maintenance_plan: "Maintenance plan",
    type: "Type",
    hours_from: "Selected hours",
    hours_to: "to",
    drag: "Drag to calendar",
    drag_tooltip: "For drag choose person",
    week: "Week",
    apply: "Apply plan",
    today: "Today",
    label: {
      time_from: "Display from",
      time_to: "Display to",
      table_mode: "Table",
    },
    coloring: {
      label: "Coloring",
      default: "Default",
    },
    types: {
      month: "Month",
      week: "Week",
      day4: "4 days",
      day: "Day",
      year: "Year",
      scheduler: "Scheduler",
    },
    apply_dialog: {
      total: "Total",
      success: "Successfully planned",
      fail: "Fail to plan",
      not_changed: "Already planned",
      source: "Source of failure",
      no_service: "Issue without service",
      no_plan_state: "State not configured",
      no_transition: "Transition not found",
      transition_fail: "Transition error: {message}",
    },
  },
  issue: {
    issue: "Issue",
    issues: "Issues",
    issues_without_service: "Issues without service",
    acc: "issue",
    alert: genericAlert,
    label: {
      timeline: "Timeline",
      transactions: "Transactions",
      change_state: "Change state",
      create: "Create",
      update: "Update",
      destroy: "Delete",
      to: "to",
      difference: "Difference",
      identifier: "Identifier",
      planned_at: "Planned at",
      resolve_at: "Resolve at",
      closed_at: "Closed at",
      public_token_valid: "Public",
      public_link: "Public link",
      resolve_from: "Resolve from",
      resolve_to: "Resolve to",
      change_state_dialog: "Change issue state",
      no_state: "No state",
      and_more: " and {number} more",
      solution: "Solution",
      display_hidden: "Display hidden issues",
      sla_response_time_estimated: "Response - Limit by SLA",
      sla_response_time_actual: "Response - Actual Time",
      sla_resolution_time_estimated: "Resolution - Limit by SLA",
      sla_resolution_time_actual: "Resolution - Actual Time",
      submenu: {
        link_issue: "Manage linked issues",
        create_reminder: "Create reminder",
      },
      issues_history: "Issues history",
      change_service: "Change service",
      requested_service: "Requested service",
      requested_state: "Requested state",
    },
    hint: {
      service: "Service that covers issue",
    },
    public_form: {
      new: "New Issue",
      continue: "Continue",
      back: "Back",
      skip: "Skip",
      qr_code: "Failure on",
      email: "Your contact email",
      title: "Reporting issue",
      title_files: "Do you have some files to attach?",
      description_files:
        "You can optionally attach up to 5 images or other files to your issue.",
      title_qr: "Is visible QR code on asset?",
      description_qr:
        "Scanning the QR code broken asset can help resolving the problem. " +
        "If there is no QR code or your device doesn't have camera, you can skip this step.",
      title_contact: "Let us contact on you",
      description_location:
        "Saving your current location can help us find the problem faster.",
      location_header: "Do you want to save the exact location of the issue?",
      location_save: "Save current location",
      location_info:
        "You must have location sharing enabled in your browser to save your current location. " +
        "If you have disabled browser location sharing and want to use location, " +
        "you will need to change this setting and reload the page.",
      description_contact:
        "Please let us contact on you. " +
        "We need it for future communication and informing you about resolving the problem.",
      title_thank: "Thank you for your report",
      description_thank:
        "Progress of solving your issue can be tracked by link sent to your email.",
      sorry: "Sorry, can't display issue.",
    },
  },
  kpi: {
    kpi: "KPI",
    kpis: "KPI",
    acc: "KPI",
    alert: genericAlert,
    label: {
      computed_at: "Computed at",
      refresh_rate: "Refresh rate",
      data: "Data",
      definition: "Definition",
      preview: "Preview",
      visualization: "Visualization",
      calculation: "Calculation",
      key: "Option",
      value: "Value",
      next: "Next",
      back: "Back",
      legend: "Legend",
      count: "Count",
      no_issues: "No Issues",
      error: "Error computing KPI",
      no_data: "No data. Check options",
      submenu: {
        compute_now: "Compute now",
        download: "Download",
      },
    },
    refresh_rates: {
      0: "None",
      60: "1 Hour",
      360: "6 Hours",
      1440: "24 Hours",
    },
    visualizations: {
      map: "Map",
      pie_chart: "Pie chart",
      donut_chart: "Donut chart",
      column_chart: "Column chart",
      bar_chart: "Bar chart",
      line_chart: "Line chart",
      area_chart: "Area chart",
      time_line: "Time line",
      stacked_column_chart: "Stacked column chart",
      stacked_bar_chart: "Stacked bar chart",
      table: "Table",
    },
    calculations: {
      ratio: "Ratio",
      issue_count: "Issue count",
      time_series: "Time series",
      state_change: "Change of state",
      state_sum: "Change of state summary",
      detail_sum: "Sum by field in detail",
      map: "Map",
      map_colored: "Map by priority",
      consumption: "Consumption",
      transaction_item_sum: "Sum of transaction items",
    },
    display_type: {
      percentage: "Percentage",
      value: "Value",
      label: "Label",
      none: "No display",
    },
  },
  connector: {
    connector: "Connector",
    connectors: "Connectors",
    acc: "connector",
    alert: genericAlert,
    label: {
      type: "Type",
      status: "Status",
      on: "Turned on",
      connector_type: "Connector type",
      login: "Login",
      url: "URL",
      queries: "Queries definition",
      mail_receiver: {
        host: "Host",
        imap_port: "IMAP port",
        smtp_port: "SMTP port",
        user_name: "User name",
      },
      mail_ms_graph: {
        tenant_id: "Tenant ID",
        client_id: "Client ID",
        mailbox: "Mailbox",
      },
      pohoda_warehouse: {
        synchronize_warehouses: "Sychronize warehouses",
        ico: "ICO",
      },
      ldap: {
        port: "Port",
      },
      ftp: {
        path: "Path to source directory",
      },
      diagnostics: {
        site_category: "Site category",
        device_categories: "Device categories",
      },
      without: "Without connector",
    },
    type: {
      mail_receiver: "IMAP",
      mail_ms_graph: "MS Graph email",
      pohoda_warehouse: "Pohoda",
      ldap_auth: "LDAP",
      ftp: "FTP download",
      ftp_sender: "FTP upload",
      sftp: "SFTP download",
      sftp_sender: "SFTP upload",
      ca_sdm: "CA SDM REST",
      dpd_sap: "DPD SAP REST",
      document_saver: "Document saver",
      qualys: "Qualys",
      sccm: "SCCM",
      diagnostics: "Diagnostics",
    },
  },
  connector_run: {
    connector_run: "Run",
    connector_runs: "Runs",
    acc: "run",
    alert: genericAlert,
  },
  connector_result: {
    connector_result: "Connector result",
    connector_results: "Connector results",
    acc: "connector result",
    alert: genericAlert,
  },
  mapper: {
    mapper: "Mapper",
    mappers: "Mappers",
    acc: "mapper",
    alert: genericAlert,
    label: {
      mapper_type: "Mapper type",
      definition: "Definition",
    },
    mapper_types: {
      mail_receiver_mapper: "Issues from email",
      pohoda_warehouse_mapper: "Pohoda - warehouse mapper",
      csv_mapper: "CSV -> assets/persons/issues",
      csv_generator: "Assets/persons/issues -> CSV",
      json_mapper: "JSON -> assets/persons",
      viskb_xml_generator: "Assets -> VISKB XML",
    },
  },
  external_email: {
    external_email: "External email",
    external_emails: "External emails",
    acc: "external email",
    alert: genericAlert,
    label: {
      submenu: {},
    },
  },
  permission: {
    permission: "Permission",
    permissions: "Permissions",
    acc: "permission",
    selection: "Object type",
    selectTypeAndPerson: "Select object type and person",
    tooltip: {
      see: "See",
      create: "Create",
      update: "Update",
      destroy: "Delete",
      manage_permissions: "Manage permissions",
      see_in_object: "See in object",
      update_in_object: "Update in object",
      see_issues: "See issues",
      update_issues: "Update issues",
    },
    menu: {
      unset: "Not set",
      unchanged: "Not changed",
      allowObject: "Allowed ",
      allowObjectAndChildren: "Allowed with inheritance",
      denyObject: "Denied",
      denyObjectAndChildren: "Denied with inheritance",
    },
    label: {
      type: "Type",
      permitted_persons: "Permitted persons",
      persons_text_1: "Person",
      persons_text_2: "and all children in organization structure.",
      roles_text_1: "All persons assigned to role",
      assignee: "(Permission from assigned)",
      detail: "Permission detail",
      multiple_setup: "Setup for selected",
      entity: "Object",
      owner: "Permission owner",
    },
  },
  role: {
    role: "Role",
    roles: "Roles",
    acc: "role",
    alert: genericAlert,
    label: {
      configuration: "Configuration",
      submenu: {
        setup_persons: "Setup persons",
      },
    },
  },
  user: {
    user: "User",
    users: "Users",
    acc: "user",
    alert: genericAlert,
    label: {
      name_p: "Name",
      block_status: "Blocked",
      two_factor_auth: "Two factor authentication",
      status: "Status",
      is_turned_on: "is turned on",
      turn_on: "Turn on",
      is_turned_off: "is turned off",
      turn_off: "Turn off",
      turn_2fa_off: "Turn off two factor authentication",
      confirm: "Confirm",
      two_factor_auth_label:
        "Scan this QR code with two factor authentication mobile app. Enter generated code and confirm.",
      submenu: {
        resend_instructions: "Send recovery instructions",
      },
      impersonate: "Impersonate user",
      phone: "Phone number",
    },
    tier_types: {
      admin: "Admin",
      support: "Support",
      full: "Full access",
      limited: "Limited access",
      external: "External access",
    },
  },
  asset_category: {
    asset_category: "Asset category",
    asset_categories: "Asset categories",
    acc: "category",
    alert: genericAlert,
  },
  person_category: {
    person_category: "Person category",
    person_categories: "Person categories",
    acc: "category",
    alert: genericAlert,
  },
  category: {
    category: "Category",
    categories: "Categories",
    acc: "category",
    alert: genericAlert,
    label: {
      remove_from: "Remove from",
      icon: "Icon",
      floor_plan: "Floor plan",
      persons: "Persons",
      organization: "Organization",
      basic: "Basic",
      buildings: "Buildings",
      security: "Security",
      cars: "Cars",
      ikt: "Information and communication technologies",
      cyber_sec: "Cyber security",
      energetics: "Energetics",
      submenu: {
        new_cat: "Create new category",
        existing_cat: "Add existing category",
      },
    },
  },
  template: {
    template: "Template",
    templates: "Templates",
    acc: "template",
    alert: genericAlert,
    detail: "Detail",
    empty: "Can't display detail on object",
    raw: "Raw data",
    name: "Template name",
    label: {
      definition: "Definition",
      key: "Key",
      type: "Type",
      template_on_objects: "Template on objects assignment",
      uniqueLastValue: "Max value",
      yes: "Yes",
      no: "No",
      field: "Field",
      data_type: "Data type",
      new_field: "New field",
      new_option: "New option",
      form: "Form",
      required: "Required",
      unique: "Unique",
      personal: "Personal data",
      notify: "Notify",
      submenu: {},
      fields: "Fields",
      multiselect: "Multiselect",
      conditional: "Turn on conditional selection",
      select_from: "Conditional on selection from",
      option: "Option",
      for_checklist: "Checklist template",
      show_on_public_page: "Public issue",
      show_on_public_map: "Public map",
    },
  },
  field: {
    field: "Field",
    fields: "Fields",
    acc: "field",
    alert: genericAlert,
    data_types: {
      String: "String",
      Text: "Text",
      Integer: "Number",
      Date: "Date",
      Time: "Time",
      Boolean: "Boolean",
      Switch: "Switch",
      Select: "Select",
      Radio: "Radio",
      Picture: "Picture",
      Person: "Person",
      Asset: "Asset",
      Issue: "Issue",
      Password: "Password",
      DateTime: "Date and time",
    },
  },
  field_assignment: {
    field_assignment: "Field assignment",
    field_assignments: "Field assignments",
    acc: "field assignment",
    alert: genericAlert,
  },
  price_item: {
    price_item: "Item",
    price_items: "Items",
    acc: "item",
    label: {
      price_type: "Item type",
      unit: "Unit",
      base: "Base item",
      quantity: "Quantity",
      price: "Price",
      batch: "Batch",
      batches: "Batches",
      gtin: "GTIN",
      code_1: "Code 1",
      code_2: "Code 2",
      submenu: {},
    },
    price_types: {
      material: "Material",
      work: "Work",
      transport: "Transport",
    },
    batch: {
      dialog: {
        header: "Batch list",
      },
      name: "Batch name",
      expiration: "Expiration date",
      quantity: "Quantity",
      warehouse_quantity: "In stock",
      alert: "In warehouse are items with sooner expiration",
    },
    units: {
      // weight
      grams: "grams",
      kilograms: "kilograms",
      tons: "tons",
      ounces: "ounces",
      pounds: "pounds",
      // length
      millimeters: "millimeters",
      centimeters: "centimeters",
      meters: "meters",
      kilometers: "kilometers",
      feet: "feet",
      miles: "miles",
      // area
      square_meters: "square meters",
      ares: "ares",
      hectares: "hectares",
      square_feet: "square feet",
      acres: "acres",
      // volume
      milliliters: "milliliters",
      liters: "liters",
      hectoliters: "hectoliters",
      cubic_meters: "cubic meters",
      pints: "pints",
      gallons: "gallons",
      // time
      seconds: "seconds",
      minutes: "minutes",
      hours: "hours",
      days: "days",
      weeks: "weeks",
      months: "months",
      years: "years",
      // IT
      megabytes: "megabytes",
      gigabytes: "gigabytes",
      terabytes: "terabytes",
      // electric
      kilowatt_hours: "kilowatt hours",
      milli_ampere_hours: "milli ampere hours",
      // other
      pieces: "pieces",
      packages: "packages",
      pallets: "pallets",
      executions: "executions",
      man_hours: "man hours",
      engine_hours: "engine hours",
      man_days: "man days",
    },
    units_short: {
      // weight
      grams: "g",
      kilograms: "kg",
      tons: "t",
      ounces: "oz",
      pounds: "lb",
      // length
      millimeters: "mm",
      centimeters: "cm",
      meters: "m",
      kilometers: "km",
      feet: "ft",
      miles: "mi",
      // area
      square_meters: "m²",
      ares: "ar",
      hectares: "ha",
      square_feet: "ft²",
      acres: "acre",
      // volume
      milliliters: "ml",
      liters: "l",
      hectoliters: "hl",
      cubic_meters: "m³",
      pints: "pt",
      gallons: "gal",
      // time
      seconds: "s",
      minutes: "min",
      hours: "hr",
      days: "d",
      weeks: "w",
      months: "mon",
      years: "yr",
      // IT
      megabytes: "MB",
      gigabytes: "GB",
      terabytes: "TB",
      // electric
      kilowatt_hours: "kWh",
      milli_ampere_hours: "mAh",
      // other
      pieces: "pc",
      packages: "pkg",
      pallets: "pal",
      executions: "exec",
      man_hours: "man hr",
      engine_hours: "engine hr",
      man_days: "man day",
    },
    alert: genericAlert,
  },
  price_items_price_list: {
    price_items_price_list: "Price list item",
    price_items_price_lists: "Price list items",
    acc: "price list item",
    alert: genericAlert,
  },
  warehouse: {
    warehouse: "Warehouse",
    warehouses: "Warehouses",
    acc: "warehouse",
    alert: genericAlert,
    label: {
      balance: "Balance",
      ordered: "Ordered",
      quantity: "Quantity",
      warehouse_from: "Warehouse from",
      warehouse_to: "Warehouse to",
      price_summary: "Price summary",
      min: "Min.",
      opt: "Opt.",
      minimal: "Minimal balance",
      optimal: "Optimal balance",
      under_limit: "Under minimum quantity",
      warehouse_transactions: "Transactions",
      transaction_type: "Type",
      changed_at: "Changed at",
      transfer_to: "Transfer to other warehouse",
      state: "State",
      submenu: {
        make_transaction: "Edit balance",
      },
      transactions: {
        in: "Receipt",
        out: "Expense",
        transfer: "Transfer",
        transfer_from: "Transfer from warehouse",
        transfer_to: "Transfer to warehouse",
        warehouse_order: "Order to warehouse",
      },
      states: {
        pending: "Pending",
        closed: "Closed",
        synchronized: "Synchronized",
      },
    },
    dialog: {
      limit_header: "Setting limits",
      start: "Start scanning items",
      scanner_header: "Item scanner",
      add_manually: "Add item manually",
      manual_header: "Adding item",
    },
    messages: {
      transactionError: "Edit at least one item",
    },
  },
  price_list: {
    price_list: "Price list",
    price_lists: "Price lists",
    acc: "price list",
    alert: genericAlert,
    label: {
      price: "Price",
      prices: "Prices",
      unit_price: "Unit price",
      currency: "Currency",
      submenu: {
        setup_prices: "Setup prices",
      },
    },
  },
  work_report: {
    work_report: "Work report",
    work_reports: "Work reports",
    acc: "work report",
    alert: genericAlert,
    label: {
      price_list: "Price list used: ",
      resources: "Quantity",
      object: "Warehouse / Person / Asset",
      author: "Author",
      summary: "Summary of resources with cost",
      items: "All work report on issue",
      total_price: "Total price",
      submenu: {},
      type: "Report type",
    },
  },
  checklist: {
    checklist: "Checklist",
    checklists: "Checklists",
    acc: "checklist",
    alert: genericAlert,
  },
  template_assignment: {
    template_assignment: "Template to object assignment",
    template_assignments: "Templates to objects assignments",
    acc: "template to object assignment",
    alert: genericAlert,
    label: {
      edit_header: "Template assignment",
    },
    usage: "Usage",
  },
  dashboard_screen: {
    dashboard_screen: "Dashboard",
    dashboard_screens: "Dashboards",
    acc: "dashboard",
    copy_dashboard: 'Copy dashboard "{name}"?',
    setup: "Dashboards setup",
    public_dsh: "Public dashboards",
    new_dashboard: "New dashboard",
    visualization: "Visualization",
    refresh_rates: {
      0: "None",
      1: "1 Minute",
      5: "5 Minutes",
      15: "15 Minutes",
    },
    alert: genericAlert,
    label: {
      public: "Shared",
      refresh_rate: "Refresh rate",
      no_public_dashboard_alert:
        "There is no shared dashboards for you to copy.",
      public_dashboard_alert:
        "You can preview and copy these shared dashboards. You can edit copied dashboard to fulfill your needs.",
      submenu: {},
    },
  },
  dashlet: {
    dashlet: "Dashlet",
    dashlets: "Dashlets",
    acc: "dashlet",
    alert: genericAlert,
    label: {
      order: "Order",
      size: "Width",
      setup_error: "Dashlet not setup",
    },
    sizes: {
      3: "25 %",
      4: "33 %",
      6: "50 %",
      8: "66 %",
      9: "75 %",
      12: "100 %",
    },
  },
  subscription: {
    subscription: "Subscription",
    subscriptions: "Subscriptions",
    acc: "subscription",
    alert: genericAlert,
    label: {
      subscription_type: "Subscription type",
      subscribed_items: "Subscribed items",
      type: "Type",
      last_one: "At least one option must be selected",
      issue_priority: "At what priority?",
    },
    subscription_types: {
      Service: "Services",
      Contract: "Contracts",
      Rule: "Rules",
      Asset: "Assets",
      AssetCategory: "Asset categories",
      Person: "Persons",
      PersonCategory: "Person categories",
      Warehouse: "Warehouses",
      PriceItem: "Price items",
      Connector: "Connectors",
      Other: "Other",
    },
  },
  entity_subscription: {
    entity_subscription: "Subscribed object",
    acc: "subscribed object",
    alert: genericAlert,
  },
  notification: {
    notification: "Notification",
    notifications: "Notifications",
    acc: "notification",
    alert: genericAlert,
    label: {
      empty: "You have no unread notifications",
      from: "From",
      mark_as_read: "Mark as read",
      open_object: "Go to this object",
      notifications_list: "Notifications list",
      on_object: "on object",
      read: "Read",
      read_all: "Mark all as read",
      view_all: "View all",
      created: "Created",
      notification_send_when: "Notification will be sent",
      when: "When you want to be notified?",
      how: "How you want to be notified?",
      notification_time: "Notification time",
      notification_time_in_advance: "Notification time in advanced",
      time: "Time",
      before_expire: "Notification time before expiration",
      before_batch_expire: "Notification time before batch expiration",
      before_notice_period: "Notification time before the notice period begins",
    },
    types: {
      issue_create: "When issue is created",
      issue_close: "When issue is closed",
      closed_issue_update: "When closed issue is updated",
      update: "When the object is updated",
      fail: "When the connector fails",
      mention: "When I'm mentioned in the note on issue",
      note_create: "When new note on issue is created",
      assign: "When I'm assigned to issue",
      issue_without_service_create: "When issue without service is created",
      expire: "When it expires",
      prolong: "When it is prolonged",
      before_notice_period: "Before the notice period begins",
      before_expire: "Before it expires",
      optimal_balance: "When the optimal count is exceeded",
      minimal_balance: "When the minimal count is exceeded",
      before_batch_expire: "Before batch expiration",
      batch_expire: "When the batch expires",
      response_time_expire: "When reaction time ends",
      before_response_time_expire: "Before reaction time ends",
      resolution_time_expire: "When resolution time ends",
      before_resolution_time_expire: "Before resolution time ends",
      issue_state_enter: "In a specific state",
      alarm: "When alarm from diagnostics is reported",
    },
    tooltip: {
      mail: "Notification by email",
      push: "Push notification",
      sms: "SMS notification",
    },
  },
  reminder: {
    reminder: "Reminder",
    reminders: "Reminders",
    acc: "reminder",
    alert: genericAlert,
    label: {
      message: "Message",
      notification_time: "Notification time",
    },
  },
  snippet: {
    snippet: "Snippet",
    snippets: "Snippets",
    acc: "snippet",
    alert: genericAlert,
    label: {
      value: "Value",
      public: "Shared",
    },
  },
  entity_document: {
    entity_document: "Link to document",
    entity_documents: "Links to documents",
    acc: "link to document",
    dialog: {
      from_document: "Create link to object",
      from_entity: "Create link to document",
      hint: "Start typing to search",
    },
    alert: genericAlert,
  },
  indoor_location: {
    indoor_location: "Indoor location",
    indoor_locations: "Indoor locations",
    acc: "indoor location",
    alert: genericAlert,
  },
  location: {
    location: "Location",
    locations: "Locations",
    acc: "location",
    alert: genericAlert,
    label: {
      indoor: "Interior",
      outdoor: "Map",
      longitude: "Longitude",
      latitude: "Latitude",
      loadName: "Load name",
      has_floor_plan: "Enable using indoor map",
      layers: "Layers",
      cadastre: "Cadastre",
      basic: "Basic",
      public_issue_map: "Issue map",
      current: "Load current location",
    },
    tooltip: {
      getCoordinates: "Get coordinates by place name",
      disabled: {
        getCoordinates: "Type full place name",
        current: "Access to the location was denied",
      },
    },
    hint: {
      latitude: "Enter in decimal format",
      longitude: "Enter in decimal format",
    },
    layer: "Layer",
    select_location: "Click on the map to change the location",
  },
  issue_note: {
    issue_note: "Note",
    issue_notes: "Notes",
    acc: "note",
    alert: genericAlert,
    note_types: {
      private: "Private",
      internal: "Internal",
      public: "Public",
    },
    note_types_acc: {
      private: "private",
      internal: "internal",
      public: "public",
    },
    label: {
      from: "From: ",
      displaying: "Displaying",
      send_email: "Send via mail",
      email_to: "To",
      email_cc: "Copy",
      email_bcc: "Hidden copy",
      loadMore: "Load more",
      note_type: "Note type",
      role_private: "Private issue note",
      linkCopied: "Link to note was copied",
      new: "New Note",
      forward: "Forward {type} note",
      reply: "Reply to {type} note",
      reply_to_all_emails: "Reply to all emails",
    },
    previous_note: "Previous note",
    hint: {
      note: "Apply snippets by character *. Apply object mentions by character @ for persons, $ for assets, % for documents a # for issues.",
    },
  },
  issue_assignee: {
    issue_assignee: "Assignee",
    issue_assignees: "Assignees",
    issue_resource: "Resource",
    issue_resources: "Resources",
    acc: "assignee",
    alert: genericAlert,
    issue_resource_edit: "Resource planning",
    label: {
      range: "Range",
      time: "Time",
      plan_start: "Time plan from",
      plan_end: "Time plan to",
      resource: "Resource",
      relation: "Relation",
    },
  },
  issue_link: {
    issue_link: "Linked issue",
    issue_links: "Linked issues",
    acc: "Linked issue",
    alert: genericAlert,
    label: {
      block: "Block",
      blocks: "Blocked issues",
      is_blocked_by: "Blocked by issues",
      related: "Related issues",
    },
  },
  assets_issue: {
    assets_issue: "Asset on issue",
    assets_issues: "Assets on issues",
    acc: "asset to issue assignment",
    issue: "Asset assigned to issue",
    asset: "Issue assigned to asset",
    alert: genericAlert,
    label: {
      solve_status: "Solving status",
      not_run: "Not run",
      success: "Success",
      fail: "Fail",
      warning: "Warning",
    },
  },
  person_role: {
    person_role: "Person to role assignment",
    person_roles: "Persons to roles assignments",
    acc: "person to role assignment",
    alert: genericAlert,
    label: {
      person_setup: "Set persons for role",
    },
  },
  person_asset: {
    person_asset: "Person to asset assignment",
    person_assets: "Persons to assets assignments",
    acc: "person to asset assignment",
    alert: genericAlert,
  },
  external_guid: {
    external_guid: "Identifier",
    external_guids: "Identifiers",
    acc: "identifier",
    alert: genericAlert,
    bar: "BAR code",
  },
  jwt_session: {
    jwt_session: "Connected device",
    jwt_sessions: "Connected devices",
    acc: "connected device",
    alert: genericAlert,
    label: {
      actual: "Actual session",
      browser: "Browser",
      firebase_token: "Firebase token",
      last_ip: "Last IP adress",
      location: "Location",
    },
  },
  tag_definition: {
    tag_definition: "Tag",
    tag_definitions: "Tags",
    acc: "tag",
    alert: genericAlert,
    label: {
      color: "Color",
      without: "Without tag",
      add: "Add tag",
      new: "Create new tag",
      filter: "Filter",
    },
  },
  issue_priority: {
    issue_priority: "Issue priority",
    issue_priorities: "Issue priorities",
    acc: "issue priority",
    alert: genericAlert,
    label: {
      priority: "Priority",
      order: "Order",
      without: "Without priority",
    },
  },
  issue_state: {
    issue_state: "Issue state",
    issue_states: "Issue states",
    acc: "issue state",
    alert: genericAlert,
    label: {
      hidden: "Hidden",
      state: "State",
      close_issue_state: "Closed issue state",
      plan_issue_state: "Plan issue state",
      create: "Create",
      start: "Start",
      pause: "Pause",
      continue: "Continue",
      finish: "Finish",
      approve: "Approve",
      without: "Without state",
    },
  },
  issue_state_transition: {
    issue_state_transitions: "Issue state transitions",
    issue_state_transition: "Issue state transition",
    acc: "issue state transition",
    alert: genericAlert,
    label: {
      source_state: "Source state",
      target_state: "Target state",
      name: "Issue name",
      description: "Issue description",
      solution: "Issue solution",
      resolve_at: "Issue resolve at",
      service: "Service",
      priority: "Issue priority",
      note: "Issue note",
      public_token_valid: "Public",
      checklists: "Resolved checklists",
      blocking_issues: "Assigned blocking issues",
      work_reports: "Work reports",
      assets: "Assets",
      files: "Files",
      persons: "Persons",
      custom_fields: "Custom fields",
      tag_assignments: "Tags",
      requirements: "Requirements",
      currently: "Currently",
      display: "Display",
      validate: "Validate",
      operation: "Operation",
      close_transaction: "Close transaction",
    },
    value: {
      optional: "Optional",
      required: "Required",
      not_validated: "Not validates",
      all_done: "All done",
      one: "At least one present",
      order: "Order",
      warehouse_order: "Order to warehouse",
      in: "Receipt at the warehouse",
      out: "Expenditure from the warehouse",
      transfer: "Moving between warehouses",
      checklists: "Checklist",
      all_closed: "All closed",
      true: "Yes",
      false: "No",
    },
  },
  person_relation: {
    person_relation: "Person relation",
    person_relations: "Person relations",
    acc: "person relation",
    alert: genericAlert,
  },
  role_issue_state_transition: {
    role_issue_state_transition: "Issue state transition to role assignment",
    role_issue_state_transitions: "Issue state transitions to role assignments",
    acc: "issue state transition to role assignment",
    alert: genericAlert,
  },
  profile: {
    profile: "Profile",
    profiles: "Profiles",
    acc: "profile",
    settings: "Settings",
    alert: genericAlert,
    email: "Email",
    change_email: "Change",
    password: "Password",
    change_password: "Change",
    password_current: "Please, enter your current password",
    password_new: "New password",
    password_new_c: "Confirm new password",
    account: "Account",
    preferences: "Preferences",
    prefer_exact_dates: "Prefer exact dates",
    dark_mode: "Dark mode",
    light_mode: "Light mode",
    locale: "Language",
    date_format: "Date format",
    time_format: "Time format",
    time_zone: "Time zone",
    week_first_day: "First day of week",
    pagination: "Pagination",
    home_page: "Home page",
    two_factor_auth: "Two factor authentication",
    devices: "Devices",
    about: "About",
    product: {
      more_1:
        "You can find more about the AUSEMIO platform and the licensing at {web}, where you will find business contacts.",
      more_2: "In case of technical questions, contact us at {mail}.",
      legal: {
        cookies: "Cookies",
        gdpr: "Data Processing",
      },
      install: {
        name: "Name",
        id: "Identifier",
        version: "Version",
        administration: "Administrator",
      },
    },
  },
  license: {
    license: "License",
    label: {
      type: "User type",
      monthly: "Monthly",
      yearly: "Yearly",
    },
    items: {
      title: "License",
      payment: "Payment",
      users: "Users",
      storage: "Storage",
      connectors: "Connectors",
      of: "of",
      modules: "Modules",
    },
  },
  administration: {
    administration: "Administration",
    administrations: "Administrations",
    acc: "administration",
    alert: genericAlert,
  },
  import: {
    import: "Import",
    imports: "Import",
    acc: "import",
    alert: genericAlert,
    label: {
      link_to: "Link document to",
      export_question: "Do you want to export {model}?",
      export_text: "Filtered {model} will be exported in .xlsx format",
      custom_export_text: "{model} will be exported in .xlsx format",
      failed_row_number: "Failed row number",
      failed_message: "Fail message",
      operation: "Operation",
      status: "Status",
      stop: "Stop import",
    },
  },
  implementation: {
    implementation: "Implementation",
    implementations: "Implementations",
    acc: "implementation",
    alert: genericAlert,
    smtp: {
      label: {
        address: "Address",
        authentication: "Authentication",
        domain: "Domain",
        host: "Host",
        openssl: "Open SSL verify mode",
        port: "Port",
        starttls: "Enable STARTTLS",
        tls: "TLS",
        user_name: "User name",
      },
    },
    menu: {
      notice: "Notice",
      calendar: "Calendar",
      statistics: "Statistics",
      health_check: "Health check",
      mail_server: "Email server",
      issue_state_transitions: "Issue state transitions",
      user_settings: "Default user settings",
      document_settings: "Document settings",
      new_passwd: "Set up new password",
      public_form: "Public issue form",
      background_jobs: "Background jobs",
      installation: "Installation",
      holidays: "Holidays",
      branding: "Branding",
    },
    label: {
      // issue states and priorities dialog
      color: "Color",
      db_representation: "Database representation",
      max_filesize: "Max file size in MB",
      compress_dimension: "Max image compress dimension in px",
      notice: "Notice",
      severity: "Severity",
      setup_notice: "Setup notice",
      category: "Select category to generate import script",
      generate: "Generate XLSX file",
      read_file: "Read import file",
      run_import: "Run import",
      new_import: "New import",
      hide_table: "Hide table (recommended when processing 100 and more rows)",
      public_form: {
        form: "Whole public issue form",
        step_1: "Step 1 (files)",
        step_2: "Step 2 (QR code scanning)",
        step_3: "Step 3 (location)",
      },
      health_check: {
        asset_no_category: "Assets without category",
        person_no_category: "Persons without category",
        asset_no_parent: "Assets not placed in a tree",
        person_no_parent: "Persons not placed in a tree",
        document_no_parent: "Documents not placed in a tree",
        documents_over_limit: "Documents over limit",
      },
      severity_levels: {
        info: "Info",
        error: "Error",
        warning: "Warning",
        success: "Success",
      },
      documents: {
        person_documents: "Person documents",
        person_avatars: "Person avatars",
        person_detail: "Person detail",
        person_reports: "Person reports",
        asset_documents: "Asset documents",
        asset_avatars: "Asset avatars",
        asset_detail: "Asset detail",
        asset_reports: "Asset reports",
        asset_checklists: "Asset checklists",
        contract_documents: "Contract documents",
        service_documents: "Service documents",
        rule_documents: "Rule documents",
        issue_documents: "Issue documents",
        issue_detail: "Issue detail",
        issue_reports: "Issue reports",
        audit_documents: "Audit documents",
        audit_evidences: "Audit evidence",
        audit_reports: "Audit reports",
        price_list_reports: "Price list reports",
        default_folder_settings: "Default file save settings for:",
      },
    },
    hint: {
      documents_import: {
        filters: "Documents will be linked to filtered objects",
      },
    },
  },
  activity: {
    activity: "Activity",
    activities: "Activities",
    acc: "activity",
    label: {
      activity_type: "Activity type",
      author_type: "Author type",
      changed: "Changed",
      changed_at: "Changed at",
      changed_by: "Changed by",
      from: "From",
      to: "To",
      diff: "Differences",
      attribute: "Attribute",
    },
    list_label: {
      created_at: "Created at",
      created_by: "Created by",
      activity_type: "Activity type",
      object_type: "Object type",
      object_id: "Object ID",
      name: "Name",
      before: "Before",
      after: "After",
    },
    activity_types: {
      create: "Create",
      update: "Update",
      destroy: "Destroy",
      log_in: "Log In",
      log_out: "Log Out",
      other: "Other",
      archive: "Archive",
      unarchive: "Unarchive",
    },
    timeline: {
      chips: {
        issue: "@:issue.issue",
        tag_assignment: "@:tag_definition.tag_definitions",
        entity_document: "@:document.documents",
        issue_note: "@:issue_note.issue_notes",
        issue_assignee_issue: "@:issue_assignee.issue_resources",
        assets_issue_issue: "@:asset.assets",
        work_report: "@:work_report.work_reports",
        location: "@:location.location",
        issue_link: "@:issue_link.issue_links",
        asset: "@:asset.asset",
        issue_assignee_asset:
          "@:issue.issues (@:issue_assignee.issue_resource)",
        person_asset: "@:person.persons",
        assets_issue_asset: "@:issue.issues",
        external_guid: "@:external_guid.external_guid",
      },
      issue: {
        create: "created this",
        update: "edited this",
        destroy: "deleted this",
        state_change: "changed state to",
      },
      asset: {
        create: "created this",
        update: "edited this",
        destroy: "deleted this",
        archive: "archived this",
        unarchive: "unarchived this",
      },
      assets_issue: {
        create: "assigned asset to issue",
        update: "edited asset on issue",
        destroy: "removed asset from issue",
      },
      issue_link: {
        create: "assigned",
        update: "edited",
        destroy: "deleted",
      },
      reminder: {
        create: "set",
        update: "edited",
        destroy: "removed",
      },
      issue_assignee: {
        create: "assigned",
        update: "edited",
        destroy: "removed",
      },
      entity_document: {
        create: "assigned",
        update: "edited",
        destroy: "removed",
      },
      person_asset: {
        create: "assigned",
        update: "edited",
        destroy: "removed",
      },
      work_report: {
        create: "assigned",
        update: "edited",
        destroy: "removed",
      },
      external_guid: {
        create: "assigned",
        update: "edited",
        destroy: "removed",
      },
      location: {
        create: "assigned",
        update: "edited",
        destroy: "removed",
      },
      issue_note: {
        create: "add",
        update: "edited",
        destroy: "removed",
      },
      tag_assignment: {
        create: "assigned",
        update: "edited",
        destroy: "removed",
      },
      generic: {
        issue: "issue",
        asset: "asset",
        issue_link: "link to issue",
        issue_assignee: "resource",
        assets_issue: "",
        reminder: "reminder",
        issue_note: "note",
        work_report: "work report",
        entity_document: "document",
        person_asset: "person",
        external_guid: "identifier",
        location: "location",
        tag_assignment: "tag",
      },
    },
  },
  filter: {
    filter: "Filter",
    filters: "Filters",
    acc: "filter",
    alert: genericAlert,
    existence_levels: {
      all: "All",
      archived: "Only archived",
    },
    otp_required_for_login_levels: {
      two_factor_auth: "2FA",
      without_two_factor_auth: "Without 2FA",
    },
    blocked_levels: {
      blocked: "Blocked",
      not_blocked: "Not blocked",
    },
    public_levels: {
      shared: "Shared",
      private: "Private",
    },
    on_levels: {
      on: "Enabled",
      off: "Disabled",
    },
    public_token_valid_levels: {
      public: "Public",
      internal: "Internal",
    },
    not_planned_levels: {
      planned: "Planned",
      not_planned: "Not planned",
    },
    below_min_levels: {
      below: "Below minimum",
      above: "Above minimum",
    },
    above_opt_levels: {
      below: "Below optimum",
      above: "Above optimum",
    },
    with_user_levels: {
      with_user: "With user",
      without_user: "Without user",
    },
    actions: {
      subheaders: {
        savedFilters: "Saved filters",
      },
    },
  },
  data_set: {
    data_set: "Filter",
    data_sets: "Filters",
    acc: "filter",
    alert: genericAlert,
    label: {
      target_entity: "Target data type",
      definition: "Definition",
      edit_filter_definition: "Edit filter definition",
      definition_from_filter: "Load definition from filter",
      edit_definition: "Edit definition",
      public: "Shared",
    },
    target_entities: {
      Activity: "@:activity.activity",
      Asset: "@:asset.asset",
      AssetCategory: "@:asset_category.asset_category",
      AssetGroup: "@:asset_group.asset_group",
      AssetsIssue: "@:checklist.checklist",
      AutomatedAction: "@:automated_action.automated_action",
      Contract: "@:contract.contract",
      DataSet: "@:data_set.data_set",
      Document: "@:document.document",
      ExternalEmail: "@:external_email.external_email",
      Field: "@:field.field",
      Issue: "@:issue.issue",
      Kpi: "@:kpi.kpi",
      Person: "@:person.person",
      PersonCategory: "@:person_category.person_category",
      PriceItem: "@:price_item.price_item",
      PriceItemsWarehouse: "@:price_items_warehouse.price_items_warehouse",
      PrimaryAsset: "@:primary_asset.primary_asset",
      PriceList: "@:price_list.price_list",
      Report: "@:report.report",
      Requirement: "@:requirement.requirement",
      Role: "@:role.role",
      Service: "@:service.service",
      Snippet: "@:snippet.snippet",
      Subscription: "@:subscription.subscription",
      Threat: "@:threat.threat",
      Translation: "@:translation.translation",
      User: "@:user.user",
      Vulnerability: "@:vulnerability.vulnerability",
      Warehouse: "@:warehouse.warehouse",
      WarehouseTransactionItem:
        "@:warehouse_transaction_item.warehouse_transaction_item",
    },
    multi_operations: {
      add_note_to_issue: "Add note",
      change_issue_state: "Change state",
      link_to_document: "Link document",
      change_parent: "Change placement in tree",
      link_assets_to_person: "Link to person",
      link_persons_to_asset: "Link asset",
      link_documents_to_entity: "Link to object",
      compress_documents: "Compress images",
      add_tag_to_entity: "Add tag",
      remove_tag_from_entity: "Remove tag",
      add_person_to_issues: "Assign person",
      remove_person_from_issues: "Remove person",
      remove_assets_from_person: "Remove from person",
      archive_multiple: "Archive",
      destroy_multiple: "Delete",
      export: "Export",
      report: "Generate report",
    },
    multi_operations_hint: {
      generic:
        "Other operations cannot be run because you have filtered more than 1000 items.",
    },
  },
  report: {
    report: "Report",
    reports: "Reports",
    acc: "report",
    alert: genericAlert,
    label: {
      target_entity: "Target data type",
      report_type: "Report type",
      generate: "Generate",
      submenu: {
        generate_report: "Generate report",
        subheader: "Reports",
      },
      contains_qr: "Contains QR code",
      contains_avatar: "Contains avatar",
    },
    messages: {
      hint: "The report will be generated in the background and the file will be saved in the selected folder. You will be notified about completion by a notification in the application.",
    },
    report_types: {
      single_entity: "Single object",
      data_set: "Filter",
    },
    target_entities: {
      Issue: "@:issue.issue",
      Asset: "@:asset.asset",
      Person: "@:person.person",
      PriceList: "@:price_list.price_list",
      Audit: "@:audit.audit",
      PrimaryAsset: "@:primary_asset.primary_asset",
      AssetGroup: "@:asset_group.asset_group",
    },
  },
  automated_action: {
    automated_action: "Automated action",
    automated_actions: "Automated actions",
    acc: "automated action",
    alert: genericAlert,
    label: {
      automated_action_type: "Automated action type",
      run_type: "Run type",
      definition: "Definition",
      edit_definition: "Edit definition",
      on: "Enabled",
      activity: "Action type",
      author_type: "Author type",
      recipient_type: "Recipient type",
      variable: "Variable",
    },
    stepper: {
      basic: "Basic settings",
      trigger: "Trigger",
      action: "Action",
    },
    automated_action_types: {
      notify: "Send notification",
      report: "Generate report",
      create: "Create object",
      update: "Update object",
      archive: "Archive object",
      destroy: "Destroy objects",
    },
    run_types: {
      now: "Now",
      delayed: "Delayed",
    },
  },
  holiday: {
    holiday: "Holiday",
    holidays: "Holidays",
    acc: "holiday",
    alert: genericAlert,
    label: {
      country: "Country",
      date: "Date",
    },
  },
  sla: {
    sla: "SLA",
    slas: "SLA",
    acc: "SLA",
    alert: genericAlert,
    label: {
      states: "States",
      response_time: "Reaction time",
      resolution_time: "Resolution time",
      response_final_states: "Reaction - final states",
      resolution_final_states: "Resolution - final states",
      response_paused_states: "Reaction - paused states",
      resolution_paused_states: "Resolution - paused states",
      setup_states: "Setup SLA states",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wedensday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      holiday: "Holiday",
      holidays_countries: "Countries for holidays",
      from: "From",
      to: "To",
      limit_by_sla: "SLA Limit",
      actual_time: "SLA Actual Time",
      status: "SLA Status",
      response: "Response",
      resolution: "Resolution",
    },
    statuses: {
      in_progress: "In progress",
      in_progress_overdue: "In progress, overdue",
      resolved: "Resolved",
      resolved_overdue: "Resolved, overdue",
      paused: "Paused",
      unknown: "Unknown",
    },
  },
  risk_management: {
    risk_management: "Risk management",
    acc: "risk management",
    alert: genericAlert,
  },
  vulnerability: {
    vulnerability: "Vulnerability",
    vulnerabilities: "Vulnerabilities",
    acc: "vulnerability",
    alert: genericAlert,
    label: {
      add: "Add vulnerability",
    },
  },
  vulnerability_control: {
    vulnerability_control: "Control assignment",
    vulnerability_controls: "Control assignments",
    acc: "control assignment",
    alert: genericAlert,
  },
  threat_vulnerability: {
    threat_vulnerability: "Vulnerability assignment",
    threat_vulnerabilities: "Vulnerability assignments",
    acc: "vulnerability assignment",
    alert: genericAlert,
  },
  threat: {
    threat: "Threat",
    threats: "Threats",
    acc: "threat",
    alert: genericAlert,
    label: {
      add: "Add threat",
      confidentiality: "Impact on confidentiality",
      integrity: "Impact on integrity",
      availability: "Impact on availability",
    },
  },
  category_threat: {
    category_threat: "Threat assignment",
    category_threats: "Threat assignments",
    acc: "threat assignment",
    alert: genericAlert,
  },
  consequence: {
    consequence: "Consequence",
    consequences: "Consequences",
    acc: "consequence",
    alert: genericAlert,
  },
  likelihood: {
    likelihood: "Likelihood",
    likelihoods: "Likelihoods",
    acc: "likelihood",
    alert: genericAlert,
  },
  risk_level: {
    risk_level: "Risk level",
    risk_levels: "Risk levels",
    acc: "risk level",
    alert: genericAlert,
    label: {
      color: "Color",
    },
  },
  risk: {
    risk: "Risk",
    risks: "Risks",
    acc: "risk",
    alert: genericAlert,
    label: {
      consequence: "Consequence",
      consequenceShort: "C",
      likelihood: "Likelihood",
      likelihoodShort: "L",
      risk: "Risk",
      riskShort: "R",
      valuation: "Risk Rating",
      not_rated: "Not Rated",
      network_graph: "Network Graph",
      primary_asset: "Primary active",
      supporting_assets: "Supporting asset category",
      effectiveness: "Effectiveness",
      real_effectiveness: "Real effectiveness",
      planned_effectiveness: "Planned effectiveness",
      reduction: "Control weight",
      current_risk: "Current risk",
      residual_risk: "Residual risk",
      treatment_kind: "Treatment plan",
      risk_analysis: "Risk analysis",
    },
    treatment_kind: {
      null: "-",
      reduce: "Reduction",
      accept: "Acceptance",
      share: "Sharing",
    },
  },
  primary_asset: {
    primary_asset: "Primary asset",
    primary_assets: "Primary assets",
    acc: "primary asset",
    alert: genericAlert,
    confidentiality_types: {
      public: "Public",
      internal: "Internal",
      protected: "Protected",
      strictly_protected: "Strictly protected",
    },
    integrity_types: {
      low: "Low",
      medium: "Medium",
      high: "High",
    },
    availability_types: {
      low: "Low",
      medium: "Medium",
      high: "High",
    },
  },
  asset_group: {
    asset_group: "Supporting asset",
    asset_groups: "Supporting assets",
    acc: "supporting assets",
    alert: genericAlert,
    label: {
      add: "Add supporting assets",
    },
  },
  soa: {
    soa: "Statement of applicability",
    soas: "Statements of applicability",
    acc: "statement of applicability",
    alert: genericAlert,
    label: {
      reason: "Reason",
      applicable: "Applicable",
    },
  },
  soa_control: {
    soa_control: "Control",
    soa_controls: "Controls",
    acc: "control",
    alert: genericAlert,
  },
  asset_link: {
    asset_link: "Asset link",
    asset_links: "Asset links",
    acc: "asset link",
    alert: genericAlert,
  },
  control: {
    control: "Control",
    controls: "Controls",
    acc: "control",
    alert: genericAlert,
    label: {
      add: "Add control",
      applied: "Applied controls",
      planned: "Planned controls",
      control: "1 control | {n} controls",
      note: "Note",
      budget: "Budget",
      time: "Time",
      deadline: "Deadline",
      accept: "I accept",
      warning_message:
        "When saved, the value of the applied control will be overwritten, and all other values will be deleted.",
    },
  },
  controls_management: {
    controls_management: "Controls management",
  },
  audit: {
    audit: "Audit",
    audits: "Audits",
    acc: "audit",
    alert: genericAlert,
    label: {
      compliance: "Compliance",
      partial_compliance: "Partial compliance",
      non_compliance: "Non compliance",
      not_applicable: "Not applicable",
      unrated: "Unrated",
      auditor: "Auditor",
      audit_organization: "Audited organization",
      representative: "Representative of the organization",
      submission_date: "Date of submission",
      requirements: "Requirements",
      evidence: "Evidence",
      questions: "Questions",
      findings: "Answers",
      note: "Notes",
      copy_questions: "Copy questions",
    },
  },
  question: {
    question: "Question",
    questions: "Questions",
    acc: "question",
    alert: genericAlert,
    label: {
      question: "Question",
      finding: "Answer",
      note: "Note",
    },
  },
  provision_question: {
    provision_question: "Question",
    provision_questions: "Questions",
    acc: "question",
    alert: genericAlert,
  },
  standard_soa_provision: {
    standard_soa_provision: "Requirement",
    standard_soa_provisions: "Requirements",
    acc: "requirement",
    alert: genericAlert,
  },
  recommendation: {
    recommendation: "Recommended control",
    recommendations: "Recommended controls",
    acc: "recommended control",
    alert: genericAlert,
  },
  suggestion: {
    suggestion: "Suggestion",
    suggestions: "Suggestions",
    acc: "suggestion",
    alert: genericAlert,
  },
  standard: {
    standard: "Standard",
    standards: "Standards",
    acc: "standard",
    alert: genericAlert,
    laws_standards: "Standards",
    label: {
      label: "Label",
      text: "Text",
      valid_from: "Valid from",
      valid_to: "Valid to",
    },
  },
  standard_soa: {
    standard_soa: "Statement of Applicability",
    standard_soas: "Statements of Applicability",
    acc: "statement of Applicability",
    alert: genericAlert,
    label: {
      reason: "Reason",
      applicable: "Applicable",
    },
  },
  section: {
    section: "Section",
    sections: "Sections",
    acc: "section",
    alert: genericAlert,
  },
  provision: {
    provision: "Provision",
    provisions: "Provisions",
    acc: "provision",
    alert: genericAlert,
  },
  requirement: {
    requirement: "Requirement",
    requirements: "Requirements",
    acc: "requirement",
    alert: genericAlert,
    label: {
      status: "Status",
    },
  },
  evaluation: {
    evaluation: "Evaluation",
  },
  issue_tab: {
    redirect: "View in Issues",
  },
  warehouse_transaction: {
    warehouse_transaction: "Transaction",
    warehouse_transactions: "Transactions",
    acc: "Transaction",
    alert: genericAlert,
    label: {
      transaction_type: "Transaction type",
    },
  },
  warehouse_transaction_item: {
    warehouse_transaction_item: "Transaction item",
    warehouse_transaction_items: "Transaction items",
  },
  warehouse_batch: {
    warehouse_batch: "Batch",
  },
  tag_assignment: {
    tag_assignment: "Tag assignment",
  },
  price_items_warehouse: {
    price_items_warehouse: "Warehouse item",
    price_items_warehouses: "Warehouse items",
    acc: "warehouse item",
    alert: genericAlert,
  },
  price_list_item: {
    price_list_item: "Price list item",
  },
  category_hierarchy: {
    category_hierarchy: "Category hierarchy",
  },
  background_job: {
    background_job: "Background job",
    background_jobs: "Background jobs",
    acc: "background job",
    alert: genericAlert,
    label: {
      last_error: "Last Error",
    },
  },
  external_password: {
    external_password: "External password",
  },
  service_issue_state_sla: {
    service_issue_state_sla: "SLA state on service",
  },
  translation: {
    translation: "Translation",
    translations: "Translations",
    acc: "translation",
    alert: genericAlert,
    label: {
      key: "Key",
      en: "Translation EN",
      sk: "Translation SK",
      en_short: "EN",
      sk_short: "SK",
    },
  },
  applied_control: {
    applied_control: "Applied control",
    alert: genericAlert,
  },
  cia: {
    cia: "CIA",
  },
  primary_asset_group: {
    primary_asset_group: "Supporting assets on the primary asset",
    acc: "supporting assets on the primary asset",
    alert: genericAlert,
  },
  risk_control: {
    risk_control: "Control on risk",
  },
};

export default en;
