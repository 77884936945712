import _DatabaseModel from "./_DatabaseModel";
import { mdiFolder } from "@mdi/js";

const DOCUMENTS_TAB_ENTITY = 52;

class Document extends _DatabaseModel {
  static model = "document";
  static bottomNav = { mobile: ["card", "tree"], desktop: ["list", "tree"] };
  static editDialogComponent = "documents-edit-dialog";
  static autofillParent = true;
  static createNewObject = { object: { folder: false }, params: {} };

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
    { textKey: "form.label.created_at", value: "created_at" },
    { textKey: "document.label.size", value: "doc_file_size" },
    { textKey: "form.label.archived_at", value: "discarded_at" },
  ];

  static show = {
    avatar: false,
    buttons: ["edit", "download"],
    submenuActions: ["compress", "permittedPersons", "activity", "archive"],
    reloadingModels: ["location"],
  };

  static filter = {
    parts: {
      fields: [
        "name",
        "id",
        "tag_definitions",
        "parent_id",
        "person_id",
        "entity_id",
        "entity_type",
        "public_token_valid",
        "file_size_range",
        "persistence",
      ],
      extended_fields: ["description", "time_filter"],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = [
    "link_documents_to_entity",
    "change_parent",
    "add_tag_to_entity",
    "remove_tag_from_entity",
    "compress_documents",
    "archive_multiple",
    "destroy_multiple",
  ];

  static public_token_valid_options = {
    true: "filter.public_token_valid_levels.public",
    false: "filter.public_token_valid_levels.internal",
  };

  static cardFields(object) {
    return {
      src: {
        document_id: [
          "image/bmp",
          "image/gif",
          "image/jpeg",
          "image/png",
          "image/tiff",
          "image/webp",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ].includes(object.doc_content_type)
          ? object.id
          : null,
        cardIcon: object.folder ? mdiFolder : null,
        extension: !object.folder
          ? object.doc_file_name.split(".").pop().toUpperCase()
          : null,
      },
      title: object.name,
      time: object.created_at,
      tags: true,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "form.label.file",
        value: object.doc_file_name,
        type: "plain",
        condition: object.doc_file_name,
      },
      {
        labelKey: "document.label.size",
        value: object.doc_file_size,
        type: "file_size",
        condition: !object.folder,
      },
      {
        labelKey: "document.label.public_token_valid",
        value: object.public_token_valid,
        type: "public_link",
        condition: !object.folder,
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.archived_at",
        value: object.discarded_at,
        type: "date_time",
        condition: object.discarded_at,
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "document.label.entity_document",
      permission: DOCUMENTS_TAB_ENTITY,
      component: "EntityTab",
      input_data: (context) => context.object,
      disabled: (context) => context.dataShow_disableUpdate,
    },
  ];
}

export default Document;
