import _DatabaseModel from "./_DatabaseModel";

const RULES_TAB_ASSETS = 136;
const RULES_TAB_DOCUMENTS = 137;

class Rule extends _DatabaseModel {
  static model = "rule";
  static editDialogComponent = "rules-edit-dialog";
  static createNewObject = { object: { filter: {} }, params: {} };

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
    { textKey: "rule.label.valid_from", value: "valid_from" },
    { textKey: "rule.label.valid_to", value: "valid_to" },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: ["duplicate", "manageSubscription", "activity", "destroy"],
    reloadingModels: [],
  };

  static filter = {
    parts: {
      fields: ["name", "description", "valid_at", "time_filter"],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = ["export", "link_to_document"];

  static cardFields(object) {
    return {
      title: object.name,
      date_from: object.valid_from,
      date_to: object.valid_to,
      tags: true,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "rule.label.valid_from",
        value: object.valid_from,
        type: "date",
      },
      {
        labelKey: "rule.label.valid_to",
        value: object.valid_to,
        type: "date",
        display_unset: true,
      },
      {
        labelKey: "rule.label.repeat",
        value: object.repeat,
        type: "interval",
      },
      {
        labelKey: "rule.label.in_advance",
        value: object.in_advance,
        type: "interval",
      },
      {
        labelKey: "rule.label.required_by_law",
        value: object.required_by_law,
        type: "boolean",
      },
      {
        labelKey: "form.label.updated_at",
        value: object.updated_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }

  static tabs = [
    this.detailTab,
    {
      titleKey: "document.documents",
      permission: RULES_TAB_DOCUMENTS,
      component: "DocumentsTab",
      disabled: () => false,
    },
    {
      titleKey: "asset.assets",
      permission: RULES_TAB_ASSETS,
      component: "AssetsOnRuleTab",
      input_data: (context) => context.object.services,
      disabled: (context) => context.dataShow_disableUpdate,
    },
  ];
}

export default Rule;
