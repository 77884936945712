import Category from "../classes/Category";
import { useAuthUserStore } from "../stores/authUser";

const PERSON_CATEGORIES_TAB_TEMPLATES = 108;

class PersonCategory extends Category {
  static model = "person_category";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
  ];

  static createNewObject = {
    ...Category.createNewObject,
    object: { icon: "person" },
  };

  static tabs = [
    ...Category.tabs,
    {
      titleKey: "template.templates",
      permission: PERSON_CATEGORIES_TAB_TEMPLATES,
      component: "TemplatesAssignmentsTab",
      input_data: (context) => context.object.template_assignments,
      parameters: { entity_type: "Category" },
      disabled: () => {
        const authUserStore = useAuthUserStore();
        return !authUserStore.permissions.configuration;
      },
    },
  ];
}

export default PersonCategory;
