import _DatabaseModel from "./_DatabaseModel";

const PERSONS_TAB_ASSETS = 111;
const PERSONS_TAB_CALENDAR = 112;
const PERSONS_TAB_DOCUMENTS = 113;
const PERSONS_TAB_EXTERNAL_GUIDS = 114;
const PERSONS_TAB_ISSUES = 115;
const PERSONS_TAB_WORK_REPORTS = 116;

class Person extends _DatabaseModel {
  static model = "person";
  static bottomNav = { mobile: ["card", "tree"], desktop: ["list", "tree"] };
  static showSize = 7;
  static autofillParent = true;
  static editDialogComponent = "persons-and-assets-edit-dialog";

  static createNewObject = {
    object: { category_id: null },
    params: { direct: true },
  };

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
    {
      textKey: "category.category",
      class: "text-no-wrap",
      value: "category.name",
    },
    { textKey: "form.label.archived_at", value: "discarded_at" },
  ];

  static show = {
    buttons: ["edit"],
    submenuActions: [
      "duplicate",
      "newUser",
      "manageSubscription",
      "permittedPersons",
      "activity",
      "archive",
    ],
    reloadingModels: ["person_asset", "external_guid"],
  };

  static filter = {
    parts: {
      fields: [
        "name",
        "id",
        "parent_id",
        "category_id",
        "tag_definitions",
        "detail",
        "with_user",
        "persistence",
      ],
      extended_fields: ["description", "connector_id", "time_filter"],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static operations = [
    "export",
    "report",
    "link_to_document",
    "change_parent",
    "add_tag_to_entity",
    "remove_tag_from_entity",
    "link_persons_to_asset",
    "archive_multiple",
    "destroy_multiple",
  ];

  static with_user_options = {
    true: "filter.with_user_levels.with_user",
    false: "filter.with_user_levels.without_user",
  };

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: object.category.name,
      src: object,
      tags: true,
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "category.category",
        value: object.category,
        type: "link",
        to: "person_categories",
      },
      {
        labelKey: "user.user",
        value: object.user,
        type: "link",
        to: "users",
      },
      {
        labelKey: "role.roles",
        value: object.roles,
        type: "link_array",
        to: "roles",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.archived_at",
        value: object.discarded_at,
        type: "date_time",
        condition: object.discarded_at,
      },
    ];
  }

  static tabs = [
    {
      titleKey: "template.detail",
      component: "TemplatesDetailTab",
      input_data(context) {
        return {
          object: context.object,
          templatable_id: context.object.category_id,
          properties: context.object.properties,
          template_assignments: context.object.template_assignments,
        };
      },
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "document.documents",
      permission: PERSONS_TAB_DOCUMENTS,
      component: "DocumentsTab",
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "external_guid.external_guids",
      permission: PERSONS_TAB_EXTERNAL_GUIDS,
      component: "ExternalGuidsTab",
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "asset.assets",
      permission: PERSONS_TAB_ASSETS,
      component: "PersonAssetsTab",
      parameters: { target_entity: "asset" },
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "issue.issues",
      permission: PERSONS_TAB_ISSUES,
      component: "IssuesTab",
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "calendar.calendar",
      permission: PERSONS_TAB_CALENDAR,
      component: "CalendarTab",
      disabled: () => false,
    },
    {
      titleKey: "work_report.work_reports",
      permission: PERSONS_TAB_WORK_REPORTS,
      component: "WorkReportsOnAssetsPersonsTab",
      disabled: () => false,
    },
  ];
}

export default Person;
