import Vue from "vue";
import VueRouter from "vue-router";
import qs from "qs";
import pinia from "../store/pinia";

import Document from "../../_generic/classes/Document";
import Contract from "../../_generic/classes/Contract";
import Service from "../../_generic/classes/Service";
import Rule from "../../_generic/classes/Rule";
import Kpi from "../../_generic/classes/Kpi";
import Warehouse from "../../_generic/classes/Warehouse";
import PriceList from "../../_generic/classes/PriceList";
import Connector from "../../_generic/classes/Connector";
import Mapper from "../../_generic/classes/Mapper";
import Snippet from "../../_generic/classes/Snippet";
import PriceItem from "../../_generic/classes/PriceItem";
import DataSet from "../../_generic/classes/DataSet";
import Activity from "../../_generic/classes/Activity";
import Role from "../../_generic/classes/Role";
import ExternalEmail from "../../_generic/classes/ExternalEmail";
import User from "../../_generic/classes/User";
import AutomatedAction from "../../_generic/classes/AutomatedAction";
import Report from "../../_generic/classes/Report";
import Person from "../../_generic/classes/Person";
import Asset from "../../_generic/classes/Asset";
import Issue from "../../_generic/classes/Issue";
import AssetCategory from "../../_generic/classes/AssetCategory";
import PersonCategory from "../../_generic/classes/PersonCategory";
import Translation from "../../_generic/classes/Translation";
import TagDefinition from "../../_generic/classes/TagDefinition";
import Reminder from "../../_generic/classes/Reminder";
import Notification from "../../_generic/classes/Notification";
import Subscription from "../../_generic/classes/Subscription";
import PersonRelation from "../../_generic/classes/PersonRelation";
import Holiday from "../../_generic/classes/Holiday";
import Device from "../../_generic/classes/Device";
import IssueState from "../../_generic/classes/IssueState";
import IssuePriority from "../../_generic/classes/IssuePriority";
import Vulnerability from "../../_generic/classes/Vulnerability";
import Threat from "../../_generic/classes/Threat";
import Control from "../../_generic/classes/Control";
import Consequence from "../../_generic/classes/Consequence";
import Likelihood from "../../_generic/classes/Likelihood";
import RiskLevel from "../../_generic/classes/RiskLevel";
import Audit from "../../_generic/classes/Audit";
import Standard from "../../_generic/classes/Standard";
import BackgroundJob from "../../_generic/classes/BackgroundJob";
import Soa from "../../_generic/classes/Soa";
import StandardSoa from "../../_generic/classes/StandardSoa";
import Field from "../../_generic/classes/Field";
import AssetGroup from "../../_generic/classes/AssetGroup";
import PrimaryAsset from "../../_generic/classes/PrimaryAsset";
import Risk from "../../_generic/classes/Risk";
import AppliedControl from "../../_generic/classes/AppliedControl";

import { useAuthUserStore } from "../../_generic/stores/authUser";
import { useSettingsStore } from "../../_generic/stores/settings";
import { useAppStateStore } from "../../_generic/stores/appState";
import { useMessageStore } from "../../_generic/stores/message";

const BaseView = () =>
  import("../../_generic/pages/components/base/BaseView.vue");
const BaseEmptyShow = () =>
  import("../../_generic/pages/components/base/BaseEmptyShow.vue");
const BaseShow = () =>
  import("../../_generic/pages/components/base/BaseShow.vue");
const MultiOperations = () =>
  import("../../_generic/pages/components/base/MultiOperations.vue");
const ListImport = () =>
  import("../../_functions/pages/import/components/ListImport.vue");
const DocumentsImport = () =>
  import("../../_functions/pages/import/components/DocumentsImport.vue");
const ImportJSON = () =>
  import("../../_functions/pages/import/components/ImportJSON.vue");
Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  const appStateStore = useAppStateStore(pinia);
  if (appStateStore.auth) {
    const authUserStore = useAuthUserStore(pinia);
    if (authUserStore.loaded) {
      const parts = to.path.split("/");
      const target = parts[2] === "import" ? `${parts[1]}/import` : parts[1];
      if (authUserStore.canSeeAppPartByUrl(target)) next();
      else {
        // want to access restricted application part, but don't have permission
        const messageStore = useMessageStore(pinia);
        messageStore.customMessage("messages.appPartNoAccess", "error");
        next("/about");
      }
    } else {
      appStateStore.nextRoute = { path: to.path, query: to.query };
      next("/loading");
    }
  } else {
    // go to login page if not logged in
    appStateStore.nextRoute = { path: to.path, query: to.query };
    next("/public/auth/login");
  }
};

const ifAadEnabled = (to, from, next) => {
  const appStateStore = useAppStateStore(pinia);
  const settingsStore = useSettingsStore(pinia);
  if (appStateStore.auth) next("/");
  else if (settingsStore.aad) next("/public/auth/sso");
  else next();
};

function buildChildren(klass) {
  return [
    { path: "", component: BaseEmptyShow, props: { klass } },
    { path: "operations", component: MultiOperations, props: { klass } },
    { path: ":id", component: BaseShow, props: { klass } },
  ];
}

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: () => import("../../_generic/pages/AppBar.vue"),
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: "persons/import",
          component: ListImport,
          props: { model: "person" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "persons",
          component: BaseView,
          props: { klass: Person },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Person),
        },
        {
          path: "assets/import",
          component: ListImport,
          props: { model: "asset" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "diagnostics",
          component: () =>
            import("../../_functions/pages/diagnostic/DiagnosticsWrapper.vue"),
          beforeEnter: ifAuthenticated,
          redirect: "/diagnostics/devices",
          children: [
            {
              path: "devices",
              component: () =>
                import(
                  "../../_functions/pages/diagnostic/DiagnosticsDevices.vue"
                ),
              children: [
                {
                  path: ":id",
                  component: () =>
                    import(
                      "../../_functions/pages/diagnostic/DiagnosticsDeviceDetail.vue"
                    ),
                },
              ],
            },
            {
              path: "alarms",
              component: () =>
                import(
                  "../../_functions/pages/diagnostic/DiagnosticsAlarms.vue"
                ),
            },
            {
              path: "logs",
              component: () =>
                import("../../_functions/pages/diagnostic/DiagnosticsLogs.vue"),
            },
          ],
        },
        {
          path: "assets",
          component: BaseView,
          props: { klass: Asset },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Asset),
        },
        {
          path: "documents/import",
          component: DocumentsImport,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "documents",
          component: BaseView,
          props: { klass: Document },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Document),
        },
        {
          path: "contracts/import",
          component: ListImport,
          props: { model: "contract" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "contracts",
          component: BaseView,
          props: { klass: Contract },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Contract),
        },
        {
          path: "services/import",
          component: ImportJSON,
          props: { model: "service" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "services",
          component: BaseView,
          props: { klass: Service },
          beforeEnter: ifAuthenticated,
          children: [
            ...buildChildren(Service),
            {
              path: ":id/templates",
              component: BaseShow,
              props: { klass: Service },
            },
          ],
        },
        {
          path: "issues/import",
          component: ListImport,
          props: { model: "issue" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "issues",
          component: BaseView,
          props: { klass: Issue },
          beforeEnter: ifAuthenticated,
          children: [
            ...buildChildren(Issue),
            {
              path: ":id/notes",
              component: BaseShow,
              props: { klass: Issue },
            },
            {
              path: ":id/notes/:note_id",
              component: BaseShow,
              props: { klass: Issue },
            },
            {
              path: ":id/assets_issues",
              component: BaseShow,
              props: { klass: Issue },
            },
          ],
        },
        {
          path: "kpis",
          component: BaseView,
          props: { klass: Kpi },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Kpi),
        },
        {
          path: "warehouses/import",
          component: ListImport,
          props: {
            model: "price_items_warehouse",
          },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "warehouses",
          component: BaseView,
          props: { klass: Warehouse },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Warehouse),
        },
        {
          path: "price_lists/import",
          component: ListImport,
          props: {
            model: "price_items_price_list",
          },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "price_lists",
          component: BaseView,
          props: { klass: PriceList },
          beforeEnter: ifAuthenticated,
          children: buildChildren(PriceList),
        },
        {
          path: "rules/import",
          component: ListImport,
          props: { model: "rule" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "rules",
          component: BaseView,
          props: { klass: Rule },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Rule),
        },
        {
          path: "planning_calendar",
          component: () =>
            import(
              "../../_functions/pages/calendar_persons/MaintenancePlanning.vue"
            ),
          props: { klass: Issue },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "maintenance_plan",
          component: () =>
            import("../../_functions/pages/calendar_assets/AssetsCalendar.vue"),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "standby_plan",
          component: () =>
            import(
              "../../_functions/pages/services/components/StandbyPlan.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "connectors",
          component: BaseView,
          props: { klass: Connector },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Connector),
        },
        {
          path: "mappers",
          component: BaseView,
          props: { klass: Mapper },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Mapper),
        },
        {
          path: "external_emails",
          component: BaseView,
          props: { klass: ExternalEmail },
          beforeEnter: ifAuthenticated,
          children: buildChildren(ExternalEmail),
        },
        {
          path: "permissions",
          component: () =>
            import(
              "../../_functions/pages/permissions/PermissonsPersonsTree.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "roles",
          component: BaseView,
          props: { klass: Role },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Role),
        },
        {
          path: "users/import",
          component: ListImport,
          props: { model: "user" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "users",
          component: BaseView,
          props: { klass: User },
          beforeEnter: ifAuthenticated,
          children: buildChildren(User),
        },
        {
          path: "asset_categories",
          component: BaseView,
          props: { klass: AssetCategory },
          beforeEnter: ifAuthenticated,
          children: [
            ...buildChildren(AssetCategory),
            {
              path: ":id/templates",
              component: BaseShow,
              props: { klass: AssetCategory },
            },
          ],
        },
        {
          path: "person_categories",
          component: BaseView,
          props: { klass: PersonCategory },
          beforeEnter: ifAuthenticated,
          children: [
            ...buildChildren(PersonCategory),
            {
              path: ":id/templates",
              component: BaseShow,
              props: { klass: PersonCategory },
            },
          ],
        },
        {
          path: "fields/import",
          component: ImportJSON,
          props: { model: "field" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "fields",
          component: BaseView,
          props: { klass: Field },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Field),
        },
        {
          path: "price_items/import",
          component: ListImport,
          props: { model: "price_item" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "price_items",
          component: BaseView,
          props: { klass: PriceItem },
          beforeEnter: ifAuthenticated,
          children: buildChildren(PriceItem),
        },
        {
          path: "issue_priorities/import",
          component: ListImport,
          props: { model: "issue_priority" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "issue_priorities",
          component: BaseView,
          props: { klass: IssuePriority },
          beforeEnter: ifAuthenticated,
          children: buildChildren(IssuePriority),
        },
        {
          path: "issue_states/import",
          component: ListImport,
          props: { model: "issue_state" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "issue_states",
          component: BaseView,
          props: { klass: IssueState },
          beforeEnter: ifAuthenticated,
          children: buildChildren(IssueState),
        },
        {
          path: "person_relations/import",
          component: ListImport,
          props: { model: "person_relation" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "person_relations",
          component: BaseView,
          props: { klass: PersonRelation },
          beforeEnter: ifAuthenticated,
          children: buildChildren(PersonRelation),
        },
        {
          path: "activities",
          component: BaseView,
          props: { klass: Activity },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Activity },
            },
          ],
        },
        {
          path: "reports",
          component: BaseView,
          props: { klass: Report },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Report),
        },
        {
          path: "automated_actions",
          component: BaseView,
          props: { klass: AutomatedAction },
          beforeEnter: ifAuthenticated,
          children: buildChildren(AutomatedAction),
        },
        {
          path: "tag_definitions/import",
          component: ListImport,
          props: { model: "tag_definition" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "tag_definitions",
          component: BaseView,
          props: { klass: TagDefinition },
          beforeEnter: ifAuthenticated,
          children: buildChildren(TagDefinition),
        },
        {
          path: "translations/import",
          component: ListImport,
          props: { model: "translation" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "translations",
          component: BaseView,
          props: { klass: Translation },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Translation),
        },
        {
          path: "data_sets",
          component: BaseView,
          props: { klass: DataSet },
          beforeEnter: ifAuthenticated,
          children: buildChildren(DataSet),
        },
        {
          path: "snippets",
          component: BaseView,
          props: { klass: Snippet },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Snippet),
        },
        {
          path: "reminders",
          component: BaseView,
          props: { klass: Reminder },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "notifications",
          component: BaseView,
          props: { klass: Notification },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "risks",
          component: () => import("../../_functions/pages/risks/RiskTable.vue"),
          props: { klass: Risk },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "primary_assets",
          component: BaseView,
          props: { klass: PrimaryAsset },
          beforeEnter: ifAuthenticated,
          children: buildChildren(PrimaryAsset),
        },
        {
          path: "asset_groups",
          component: BaseView,
          props: { klass: AssetGroup },
          beforeEnter: ifAuthenticated,
          children: buildChildren(AssetGroup),
        },
        {
          path: "controls_management",
          component: () =>
            import(
              "../../_functions/pages/controls_management/ControlsManagementTable.vue"
            ),
          props: { klass: AppliedControl },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "linkages_setting",
          component: () =>
            import(
              "../../_functions/pages/linkages_settings/LinkagesSettingTable.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "soas",
          component: BaseView,
          props: { klass: Soa },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Soa),
        },
        {
          path: "threats/import",
          component: ListImport,
          props: { model: "threat" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "threats",
          component: BaseView,
          props: { klass: Threat },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Threat),
        },
        {
          path: "vulnerabilities/import",
          component: ListImport,
          props: { model: "vulnerability" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "vulnerabilities",
          component: BaseView,
          props: { klass: Vulnerability },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Vulnerability),
        },
        {
          path: "controls/import",
          component: ListImport,
          props: { model: "control" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "controls",
          component: BaseView,
          props: { klass: Control },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Control),
        },
        {
          path: "consequences",
          component: BaseView,
          props: { klass: Consequence },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "likelihoods",
          component: BaseView,
          props: { klass: Likelihood },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "risk_levels",
          component: BaseView,
          props: { klass: RiskLevel },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "audits",
          component: BaseView,
          props: { klass: Audit },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Audit),
        },
        {
          path: "standard_soas",
          component: BaseView,
          props: { klass: StandardSoa },
          beforeEnter: ifAuthenticated,
          children: buildChildren(StandardSoa),
        },
        {
          path: "standards/import",
          component: ImportJSON,
          props: { model: "standard" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "standards",
          component: BaseView,
          props: { klass: Standard },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Standard),
        },
        {
          path: "subscriptions",
          component: BaseView,
          props: { klass: Subscription },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Subscription),
        },
        {
          path: "installation",
          component: () =>
            import(
              "../../_functions/pages/implementation/components/ImplementationAdministration.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "notice",
          component: () =>
            import(
              "../../_functions/pages/implementation/components/ImplementationNotice.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "statistics",
          component: () =>
            import(
              "../../_functions/pages/implementation/components/ImplementationStatistics.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "health_check",
          component: () =>
            import(
              "../../_functions/pages/implementation/components/ImplementationHealthCheck.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "mail_server",
          component: () =>
            import(
              "../../_functions/pages/implementation/components/ImplementationSmtp.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "document_settings",
          component: () =>
            import(
              "../../_functions/pages/implementation/components/ImplementationDocuments.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "user_settings",
          component: () =>
            import(
              "../../_functions/pages/implementation/components/ImplementationDefaultUserSettings.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "public_form",
          component: () =>
            import(
              "../../_functions/pages/implementation/components/ImplementationPublicForm.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "background_jobs",
          component: BaseView,
          props: { klass: BackgroundJob },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "holidays/import",
          component: ListImport,
          props: { model: "holiday" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "holidays",
          component: BaseView,
          props: { klass: Holiday },
          beforeEnter: ifAuthenticated,
          children: buildChildren(Holiday),
        },
        {
          path: "account",
          component: () =>
            import("../../_generic/pages/profile/AccountSettings.vue"),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "preferences",
          component: () =>
            import(
              "../../_functions/pages/users/profile_components/SettingsEdit.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "devices",
          component: BaseView,
          props: { klass: Device },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "about",
          component: () =>
            import(
              "../../_functions/pages/users/profile_components/AboutPage.vue"
            ),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "license",
          component: () =>
            import("../../_functions/pages/license/LicensePage.vue"),
          beforeEnter: ifAuthenticated,
        },
        {
          path: "dashboard_screens",
          component: () =>
            import(
              "../../_functions/pages/dashboards/visualization/DashboardScreen.vue"
            ),
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: () =>
                import(
                  "../../_functions/pages/dashboards/visualization/DashboardScreenView.vue"
                ),
            },
          ],
        },
      ],
    },
    {
      path: "/public",
      component: () => import("../../_generic/pages/PublicScreen.vue"),
      redirect: "/public/auth/login",
      children: [
        {
          path: "auth",
          component: () => import("../../_generic/pages/LoginWrapper.vue"),
          redirect: "/public/auth/login",
          children: [
            {
              path: "login",
              component: () =>
                import("../../_generic/pages/public_forms/LoginForm.vue"),
              beforeEnter: ifAadEnabled,
            },
            {
              path: "sso",
              component: () =>
                import("../../_generic/pages/public_forms/SsoForm.vue"),
              beforeEnter: (to, from, next) => {
                const settingsStore = useSettingsStore(pinia);
                const appStateStore = useAppStateStore(pinia);
                if (appStateStore.auth) next("/");
                else if (settingsStore.aad) next();
                else next("/public/auth/login");
              },
            },
            {
              path: "recovery",
              component: () =>
                import(
                  "../../_generic/pages/public_forms/PasswordRecoveryForm.vue"
                ),
              beforeEnter: ifAadEnabled,
            },
            {
              path: "reset",
              component: () =>
                import(
                  "../../_generic/pages/public_forms/PasswordResetForm.vue"
                ),
              beforeEnter: ifAadEnabled,
            },
          ],
        },
        {
          path: "issues/new",
          component: () =>
            import("../../_functions/pages/public_issues/PublicIssuesNew.vue"),
        },
        {
          path: "issues/map",
          component: () =>
            import("../../_functions/pages/public_issues/PublicIssuesMap.vue"),
          props: { klass: Issue },
        },
        {
          path: "issues/:id",
          component: () =>
            import("../../_functions/pages/public_issues/PublicIssuesShow.vue"),
        },
        {
          path: "documents/:id",
          component: () =>
            import(
              "../../_functions/pages/public_documents/PublicDocumentsShow.vue"
            ),
        },
      ],
    },
    {
      path: "/loading",
      component: () => import("../../_generic/pages/LoadingScreen.vue"),
    },
    // redirect old paths
    { path: "/login", redirect: "/public/auth/login" },
    { path: "/public/password/reset", redirect: "/public/auth/reset" },
    { path: "*", redirect: "/" },
  ],
  // set custom query resolver
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    var result = qs.stringify(query);

    return result ? "?" + result : "";
  },
});

export default router;
